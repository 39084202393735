@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,600;0,900;1,100;1,300;1,400;1,500;1,600;1,900&display=swap');
html{
  height: -webkit-fill-available;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  height: unset!important;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

p{
  color: #A5A1A1;

}
p,a,button,
.myform .MuiInputBase-input,input,textarea{
  font-size: 12px;
}
button{
  font-size: 12px!important;
}
a{
  text-decoration: unset;
}
*{
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-Search-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none !important;
  box-sizing: border-box;
  font-family: Roboto, "Helvetica Neue", sans-serif;

  }

.MuiButton-root {
  font-family: unset!important;
}

::-webkit-scrollbar {
  width: 5px;
  right:5px;
  position:absolute;
  transition: 0.4s;
  height: 6px;
}
body::-webkit-scrollbar-thumb {
  background: #3c3c3c;
  visibility: visible;
}

::-webkit-scrollbar-thumb {
  background: #3c3c3c;
  border-radius: 10px;
  opacity: 0;
  visibility:hidden;
  transition: 0.2s;
}
:hover::-webkit-scrollbar-thumb {
  visibility:visible;
}

/* DefaultBTN */
.defaultBTN{
  width: 100%;
  height: 50px;
  box-shadow: none!important;
}
.defaultBTN span{
  font-weight: 600;
  font-size: 12px;
}


.MuiDrawer-paper .MuiIconButton-root{
  padding: 0px;
}

.MuiTooltip-tooltip{
  font-size: 12px!important;
  padding: 10px!important;
}
.MuiTooltip-popper{
  z-index: 99999999999!important;
}
.genreshome,
.uploadmusic,
.settingscontainer,
.manageaccount,
.manageuploads{
  padding-top: 20px;
}
.genrecard{
  position: relative;
  width: 100%;
  min-height: 100px;
  border-radius: 6px;
  overflow: hidden;
  transition: 0.2s;
  margin-bottom: 15px;
  cursor: pointer;
  box-shadow: 4px 4px 20px 0px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-between;
  background-color: #DF1233;
  padding: 20px;
  padding-right: 12px;
}
.genrecard:hover{
  padding-right: 5px;
}
.genrecards .genrecard:last-child{
  margin-left: 0px;
}

.genrecard svg{
fill: #fff;
color: #fff;
font-size: 25px;
}


.genrecard .title p{
  font-weight: 600;
  display: block;
  text-transform: uppercase;
  color: #fff;
  margin: 0px;
}

.genrecard .title p:last-child{
  font-weight: 400;
  text-transform: unset;
  margin-top: 3px;
}
.genreshome .row .col-6:nth-child(5) .genrecard,
.genreshome .row .col-6:nth-child(6) .genrecard{
  margin-bottom: 0px;
}
/* Login */
.landingpage{
  height: 100vh;
}
.lbanner{
  transition: 0.4s;
  height: 20vh;
  min-height: 150px;
  position: relative;
  overflow: hidden;
}
.lbanner .bgimage {
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.lbanner .logo {
  position: absolute;
  z-index: 1;
  width: 130px;
  margin: 10px;
  left: 0px;
  top: 0px;
  z-index: 3;

}
.lbanner a {

}
.lbanner .fade{
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scale(1.2);
  z-index: 2;
}
.landingpage .lbanner video{position: absolute; left: 0px; top: 0px; height: 100%; width: 100%; object-fit: cover; z-index: 1;}




.landingpage .btns{
margin-top: 20px;
padding: 10px
}
.landingpage .btns button{
  display: block;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50px;
  font-weight: 600;
}

.landingpage .btns button:nth-child(2){
  border: 1px solid;
}
.landingpage .btns span{
  font-weight: 600;
font-size: 12px;
}
.landingpage .forgotpass{
  text-align: center;
padding-top: 10px;
}

.landingpage .forgotpass a{
  color: inherit;
}
.landingpage .forgotpass a:hover{
  color: #df1233;
}
.landingpage .myform .btns button{
width: 100%;
}
.landingpage form .title span {
  padding: 5px;
}
.landingpage header{
  background-color: transparent;
  box-shadow: unset;
  padding: 0px;
}

.landingpage .MuiTabs-root {
  min-height: 50px;
}
.landingpage .MuiTabs-root button span {
  font-size: 15px;
  transform: 0.5s;
  font-weight: 600;
}

.landingpage form .btns{
padding: 0px;
}
.landingpage .MuiTabs-indicator{
  height: 4px;
  border-radius: 10px;
}

.landingpage .MuiBox-root{
  padding: 0px;
  padding-top: 20px;
  border-bottom: 0px;
}
.landingpage .background{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.landingpage .background .bgcolor{
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  background-color: rgb(24 24 24 / 70%);
}
.landingpage .background video{
  height: 100%;
  left: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  width: 100%;
}
.landingpage .background .logo{
  position: relative; 
  z-index: 1; 
  width: 180px; 
  margin: 40px;
  cursor: pointer;
}
.landingpage .btns button{
  background-color: #DF1233;
  color: #fff;
}

.landingpage .MuiTabs-root button {
  color: #aaa;
}
.landingpage button.Mui-selected {
  color: #DF1233;
  font-weight: 600;
}
.landingpage .MuiTabs-indicator{
  background-color: #DF1233;
}
.landingpage .btns button:nth-child(1){
  background-color: #DF1233;
  color: #fff;

}
.landingpage .btns button:nth-child(2){
  border-color: #DF1233;
  color: #fff;
  background-color: transparent;
}
.myform .MuiFormControl-root {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 12px;
}
.myform .btns button{
  display: block;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.myform .btns button .MuiCircularProgress-root{
  width: 15px!important;
  height: 15px!important;
  margin-left: 10px;
}

.myform .MuiFormControl-root label {
  z-index: 1;
}
.myform .MuiFormControl-root .MuiFilledInput-root{
  background-color: transparent;
}
.myform .MuiInputLabel-filled{
  color: #DF1233;
}
.MuiInputBase-root{
  margin-top: 16px;
}
.landingpage .section{
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

/* MuiCard */



.wrapper{
  padding-left: 10px;
  padding-right: 10px;
}
.usercontainer{
  padding-bottom: 90px;
  
}


.MaxPlayer .btnsgroup div{
  display: flex;
}
.MaxPlayer .btnsgroup button{
  display: block;
  background-color: transparent!important;
  box-shadow: unset;
  height: 49px;
  border: 1px solid #282828;
  padding: 10px 2px 10px 11px;
}

.MaxPlayer .btnsgroup button .MuiCircularProgress-root{
  width: unset!important;
  height: unset!important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.MaxPlayer .btnsgroup button .MuiCircularProgress-svg{
  width: 16px!important;
  height: 16px!important;
  margin: 0 auto;
}
.MaxPlayer .MuiButton-label {
  font-size: 10px;
  /* font-weight: 600; */
}
.MaxPlayer .btnsgroup svg{
  width: 18px;
  height: 18px;
}

.MaxPlayer .btnsgroup .action-btn{
  padding: 0px 0px;
  min-width: 42px;
  margin-right: 8px;
  display: block;
  padding-bottom: 2px;
}

.MaxPlayer .btnsgroup .download-btn .downIcon{
  min-width: 34px;
  padding-left: 2px;
}

.MaxPlayer .btnsgroup .download-btn .downIcon::before{

}
.MaxPlayer .btnsgroup .action-btn p,
.MaxPlayer .download-btn .downIcon p{
  display: block;
  font-size: 11px;
  line-height: 6px;
  color: #7d7d7d!important;
  margin: 0px;
}
.MaxPlayer .btnsgroup button svg{
  color: #fff;
  fill: #fff;
  opacity: 0.3;
}
.MaxPlayer .btnsgroup .active svg{
  color: #DF1233;
  fill: #DF1233;
  opacity: 1;
  }

.spotify-mini-player{
  position: fixed;
  bottom: 10px;
  width: 100%;
  z-index: 90;
}
.spotify-mini-player .wrapper{

}
.spotify-mini-player .wrapper iframe{
  
}
/* MinPlayer */
.minPlayer{
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 90;
  right: 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 9999;
}
.minPlayer::after,
.minPlayer::before{
    background-color: #323232;
    height: 2px;
    width: 100%;
    top: 0px;
    left: 0px;
    position: absolute;
    content: "";
    z-index: 1;
}
.minPlayer::after{
  width: var(--minprogress);
  background-color: #DF1233;
  z-index: 2;
}
.hide_player{
  transition: 0.5s;
  transform: translateY(100%);
}
.minPlayer .min-container {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  width: 100%;
  align-items: center;
  padding-bottom: env(safe-area-inset-bottom);

}
.minPlayer .details{
  display: flex;
  align-items: center;
  width: calc(100% - 66px);
}
.minPlayer .details .rgt{
  display: flex;
  align-items: center;
}
.minPlayer .min-container .rgt img{
  width: 55px;
  height: 55px;
  min-height: 55px;
  min-width: 55px;
  border-radius: 3px;
  margin-left: 10px;
  transition: 0.2s;
  object-fit: cover;
  object-position: center;
}
.minPlayer .min-container .rgt img:active{
  opacity: 0.7;
}
.minPlayer .details .btns{
  display: flex;
  align-items: center;
  margin-right: 5px;
}
.minPlayer .details a{
  width: 100%;
}
.minPlayer .details .btns button{
  border-radius: 50px;
  margin-right: 7px;
  box-shadow: unset!important;
  width: 37px;
  min-width: 37px;
  height: 37px;
  background-color: #363636;
  color: #fff;
}
.minPlayer .details .btns button.Mui-disabled{
  opacity: 0.3;
  background-color: rgb(255 255 255 / 45%);
}
.minPlayer .details .btns .playbtn{
  border-radius: 50px;
  height: 50px;
  min-width: 50px!important;
  width: 50px;
}


.minPlayer .details a.titles{
  width: calc(100% - 105px);
  transition: 0.3s;

  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 15px;
    
}
.minPlayer .details a.titles,
.minPlayer,
.minplayer-skeleton{
  min-height: 70px;
}

.minPlayer .details a.titles:active,
.minPlayer .details a.titles:hover{
  padding-left: 10px;
}
.minPlayer .details a.titles p{

  font-size: 10px;
  margin: 0px;
  line-height: 12px;

}

.minPlayer .details a.titles p:first-child{
  font-weight: 600;
}
.minPlayer .details a.titles p{
  white-space: nowrap;
  width: 98%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  
}


/* Max Player */
.MaxPlayer{
  min-height: 100vh;
  margin-top: 20px;
  border-radius: 0px;
  position: relative;
}

.MaxPlayer .banner {
  min-height: 200px;
  position: relative;
  overflow: hidden;
  background-color: #212121;
}
.MaxPlayer .banner .navbar{
  background-color: transparent;
  position: relative;
}
.MaxPlayer .banner .background{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0.7;
    filter: saturate(2);
    -webkit-filter: saturate(2);
    -moz-filter: saturate(2);
    -o-filter: saturate(2);
    -ms-filter: saturate(2);
    transition: 0.6s;
}
.MaxPlayer .no_data_msg{
  height: unset;
}

.MaxPlayer .htitle{
  display: none;
}

.MaxPlayer .wrapper{
  padding-top: 0px!important;
  position: relative;
  z-index: 1;
}
.MaxPlayer .banner .wrapper{
  /* max-width: 70%;
  margin: 0 auto;
  margin-bottom: 30px; */
}
.MaxPlayer .banner-b .trackdetails{
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
}
.MaxPlayer .banner-b .trackdetails .songcover{
  height: 120px;
  display: block;
  object-fit: cover;
  object-position: center;
  max-width: 120px;
  min-width: 120px;
  border-radius: 6px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-left: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.MaxPlayer .banner-b .trackdetails .songcover:hover,
.userbanner .rgt .ppimg:active{
  transform: scale(1.05);
}
.userbanner .rgt .ppimg,
.usericon.desktop .ppimg {
  background-color: #181818;
  color: #fff;
}
.MaxPlayer .banner p{
  color: #fff;
}

.MaxPlayer .banner-b .trackdetails .titles p{
  font-weight: 600;
  text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
  margin: 0px;
  font-size: 15px;
}
.MaxPlayer .banner-b .trackdetails .titles p:last-child{
  font-size: 11px;
  opacity: 0.7;
  font-weight: 400;
  margin-top: 5px;
}
.MaxPlayer .banner-b .trackdetails .titles p a{
  color: inherit;
}
.MaxPlayer .banner-b .trackdetails .titles p a:hover{
  text-decoration: underline;
}
.MaxPlayer .banner .btns{
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 0px;
  max-width: 500px;
  margin: 8px auto 5px auto;
}

.MaxPlayer .banner .btns .smallbtn{
  height: 40px;
  width: 40px;
  min-width: 40px;
  border-radius: 50px;
  color: #fff;
}
.MaxPlayer .banner .btns .smallbtn.Mui-disabled{
  opacity: 0.3;
}

.MaxPlayer .banner .btns button:first-child svg, .MaxPlayer .banner .btns button:last-child svg {
  height: 18px;
  width: 18px;
  min-width: 18px;
}
.MaxPlayer .banner .btns button:first-child{
  margin-right: 25px;
}
.MaxPlayer .banner .btns button:last-child{
  margin-left: 25px;
}
.MaxPlayer .btns .smallbtn svg{
  width: 30px;
  height: 30px;
  color: #fff;
  fill: #fff;
}
.MaxPlayer .btns .playbtn{
  height: 65px;
  width: 65px;
  min-width: 65px;
  border-radius: 50px;
  color: #fff;
}

.MaxPlayer .btns .playbtn .MuiCircularProgress-root{
  width: unset!important;
  height: unset!important;
}
.MaxPlayer .btns .playbtn svg{
  width: 35px;
  height: 35px;
}

.MaxPlayer .timer {
  justify-content: space-between;
  display: flex;
  font-weight: 400;
  padding: 10px 0px;
  align-items: center;
}

.MaxPlayer .timer .current{
  margin-right: 10px;
}
.MaxPlayer .timer .overall{
  margin-left: 10px;

}
.MaxPlayer .timer .current,
.MaxPlayer .timer .overall{
  font-size: 11px;
  opacity: 0.7;
  margin: 0px;
}

.MaxPlayer .btnsgroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px!important;
  border-bottom: 1px solid #282828;
}

.MaxPlayer .download-btn p{
  margin: 0px;
  font-size: 11px;
  line-height: 11px;
  color: #717171!important;
}
.MaxPlayer .btnsgroup .download-btn{
  display: flex;
}
.MaxPlayer .download-btn .downIcon{
  line-height: 10px;
  border-left: 1px solid #282828;
  padding-left: 0px;
  margin-left: 10px;
  display: block;
} 
.MaxPlayer .download-btn .downIcon p{
  display: block;
  margin-top: 4px;
}



.MaxPlayer .mytabs{
  margin-bottom: 30px;
}
.MaxPlayer .mytabs button a{
  
}
.MaxPlayer .mytabs button{
  min-height: 30px;
  margin-top: 10px;
  margin-right: 10px;
  font-weight: 500!important;
}
.MaxPlayer .mytabs button.active{
  color: #df1233;
  font-weight: 600!important;
}
.MaxPlayer .mytabs button::after{
  content: "";
  position: absolute;
  bottom: -10px;
  width: 0%;
  height: 3px;
  border-radius: 50px;
  background-color: #DF1233;
  transition: 0.3s;

}
.MaxPlayer .mytabs button.active::after{
  width: 100%;
}
.MaxPlayer .playerinfo .htitle p{
  color: #DF1233;
}
/* PlayerInfo */


.playerinfo .songdetails .par{
  font-size: 12px;
  margin-bottom: 10px;
}
.playerinfo .songdetails .descr{
  white-space: pre-wrap;
  margin-bottom: 5px;
  margin-top: -5px;
}
.playerinfo .songdetails .descr a{
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}
.playerinfo .songdetails .par p:nth-child(1){
  font-weight: 600;
  margin-bottom: 5px;
  
  text-transform: uppercase;
}
.playerinfo .songdetails .par p{
  display: flex;
  margin: 0px;
}
.playerinfo .songdetails .par p:nth-child(2){
}
.playerinfo .songdetails .par .active{
  color: #DF1233!important;
  cursor: pointer;
}
.playerinfo .songdetails .descr a:hover,
.playerinfo .songdetails .par .active:hover
{
text-decoration: underline;
}
.playerinfo .songdetails .lyrics{
  white-space: pre-wrap;
  border-top: 1px solid;
  /* font-style: italic; */
  padding-top: 20px;
  background-color: transparent;
}
.playerinfo .songdetails .lyrics:active{
  background-color: #1c1c1c;
}
.playerinfo .songdetails .lyrics.zoomed{
  font-size: 15px;
}

/* Comments */
.playerinfo .comments .wrapper{

}
.playerinfo .comments .title{
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
}

.playerinfo .comments .title button svg{
  color: #aaa;
  margin-left: 10px;
}
.playerinfo .comments .title button{
  color: #aaa;
}
.playerinfo .comments .title button:hover{
  background-color: rgb(255 255 255 / 5%);
}
.playerinfo .comments{
  margin-bottom: 100px;
  padding-bottom: env(safe-area-inset-bottom);

}

.LoadMoreBTN{
  padding: 10px;
  padding-top: 0px;
}
.LoadMoreBTN button{
width: 100%;
height: 48px;
border-radius: 0px!important;
}
.LoadMoreBTN button span{
  color: #fff;
}
.CommentCard { 
    padding: 5px 0px 6px 0px;
    box-shadow: unset!important;
    border-radius: 0px!important;
    margin-bottom: 10px;
}
.CommentCard .header{
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
.CommentCard .header .user{
  display: flex;
  align-items: center;
  width: 100%;
  transition: 0.3s;
}
.CommentCard .header .user:active{
  border-radius: 50px;
}
.CommentCard .header .user .ppimg{
  min-width: 43px;
  width: 43px;
  height: 43px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
  font-size: 16px;
  text-transform: uppercase;
} 

.CommentCard .menu{
    position: relative;
    z-index: 2;
}
.CommentCard .menu button{
  width: 45px;
  height: 45px;
  min-width: 45px;
}

.CommentCard .menu svg{
  font-size: 20px;
}
.CommentCard .header .user .details p{
  display: block;
  font-size: 12px;
  font-weight: 600;
  margin: 0px;
} 
.CommentCard .header .user .details p:nth-child(2){
  font-weight: 400;
  margin-top: 3px;
}  
.CommentCard .comment {
  font-size: 12px;
  margin-left: 54px;
}
.CommentCard .comment .para{ 
 font-weight: 500;
 transition: 0.2s;
 white-space: pre-wrap;
 margin: 0px;
}
.CommentCard .comment .para a{
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}
.CommentCard .comment .reactbtns{
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: space-between;
}
.CommentCard .comment .reactbtns .lft{
  display: flex;
  align-items: center;
  margin-left: -6PX;
}
.CommentCard .comment .reactbtns .lft button{
  box-shadow: unset;
  padding: 0px 0px;
  min-width: 39px;
  min-height: 30px;
  margin-right: 5px;
}

.CommentCard .comment .reactbtns .lft button svg{
  width: 17px!important;
  height: 17px!important;
  color: #fff;
  fill: #fff;
  opacity: 0.3;
  margin-right: 5px;
}
.CommentCard .comment .reactbtns .active svg{
  color: #DF1233;
  fill: #DF1233;
  opacity: 1;
}
.CommentCard .comment .reactbtns button p{
  display: block;
  font-size: 11px;
  line-height: 10px;
  font-weight: 500;
  margin: 0px;
  color: #7d7d7d !important;
}
.CommentCard .comment .reactbtns button .MuiCircularProgress-root{
  width: unset!important;;
  height: unset!important;
}
.CommentCard .comment .reactbtns button .MuiCircularProgress-root svg{
  width: 18px!important;
  height: 18px!important;
  margin: 0px auto;
}

.CommentCard time{
  font-weight: 400;
  font-size: 9px;
  text-transform: uppercase;
  font-style: italic;
  white-space: nowrap;
}

.CommentCard .comment .reactbtns .commentreplies{

}
.CommentCard .comment .reactbtns .commentreplies .icon p{
  margin: 0px;
}
.CommentCard .comment .reactbtns .commentreplies .icon{
  height: 28px;
  width: 28px;
  min-height: 27px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  position: absolute;
  justify-content: center;
  background: #0e0e0e;
  align-items: center;
  border-radius: 50px;
  overflow: hidden;
  border: 2px solid rgb(255 255 255 / 13%);
}
.CommentCard .comment .reactbtns .commentreplies .icon:nth-child(1){
  right: 0px;
  z-index: 4;
}
.CommentCard .comment .reactbtns .commentreplies .icon:nth-child(2){
  right: 10px;
  z-index: 3;
}
.CommentCard .comment .reactbtns .commentreplies .icon:nth-child(3){
  right: 20px;
  z-index: 2;
}
.CommentCard .comment .reactbtns .commentreplies .icon:nth-child(4){
  right: 30px;
  z-index: 1;
}
.CommentCard .comment .reactbtns .commentreplies .icon img{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #353535;
  object-fit: cover;
  left: 0px;
  top: 0px;
}
.commentReply{
  position: fixed;
  background-color: rgb(0 0 0 / 66%);
  width: 100%;
  height: 100vh;
  top: 0px;
  right: 0px;
  z-index: 999;
  padding-top: env(safe-area-inset-top);
  width: 100%;
}
.commentReply .CommentBox{
  margin: 0px 10px;
  margin-top: 20px;
}
.commentReply .no_data_msg{
  height: 42vh;
}
.commentReply > .closebtn{
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: #DF1233!important;
  margin: 12px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.commentReply > .closebtn svg{
color: #fff;
font-size: 23px;
}
 .commentReply > .contain{
  height: 100vh;
  margin-top: 75px;
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  overflow-y: scroll;
  padding-bottom: 250px;

}
.commentReply > .contain{
  background-color: #181818;
}
.commentReply > .contain .title{
  color: #aaa;
}
.commentReply > .contain .CommentCard:first-child, .commentReply > .contain .comment-skeleton:first-child{
  background-color: #212121!important;
}

.commentReply > .contain .replies .CommentCard{
  background-color: transparent!important;
  margin: 0px 10px;
  padding: 10px 0px!important;
  
}
.commentReply > .contain .title{
  padding: 10px;
  padding-top: 0px;
  font-weight: 600;
  
}
 .commentReply > .contain .CommentCard:first-child,
 .commentReply > .contain .comment-skeleton:first-child{
    padding: 15px;
}
.commentReply > .contain .CommentBox{
  z-index: 5;
}
.CommentBox form{
  width: 100%;
}

.commentReply > .contain button .MuiButton-label{
  font-size: 10px;
  font-weight: 600;
}
.fadefx {
  animation-name: fadefx;
  animation-duration: 0.4s;
  animation-iteration-count: initial;
  transition: .4s;
}
@keyframes fadefx{
  0%{opacity: 0}
  100%{transform: 1}
}

.CommentBox{
  position: relative;
}

.CommentBox {
  display: flex;
}
.CommentBox textarea{
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 90px;
  background: transparent;
  border: 0px;
  padding: 10px;
  border-radius: 0px;
  margin-bottom: 10px;
  color: #fff;
  padding-left: 52px;
  padding-right: 89px;
  border-bottom: 1px solid rgb(255 255 255 / 8%);
} 
.CommentBox textarea.active{
  border-bottom: 1px solid #DF1233;
}
.CommentBox textarea:focus,
.CommentBox textarea:active{
  border-bottom: 1px solid #DF1233;
}
.CommentBox .ppimg{
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  object-fit: cover;
  object-position: center;
  background-color: #353535;
  color: #fff;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  text-transform: uppercase;
}
.CommentBox .ppimg:hover{
  opacity: 0.7;
}


.CommentBox button{
  box-shadow: none;
  padding: 7px 8px;
  color: #fff;
  background-color: #DF1233;
  font-size: 12px;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 0px;
  line-height: 19px;
  font-size: 11px!important;
}
.CommentBox button .MuiCircularProgress-root{
  width: 13px!important;
  height: 13px!important;
  margin-left: 5px;
}
.CommentBox button svg{
 color: #fff;
}

.MaxPlayer .albuminfo {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.MaxPlayer .albuminfo  .songcover{
  width: 100px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 6px;
}

.MaxPlayer .albuminfo  button{ 
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-shadow: unset;
}

.playerinfo .albuminfo .title {
  font-weight: 600;
  padding: 10px;
  font-size: 12px;
}
.MaxPlayer .albumactions{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px solid #282828;
  margin-bottom: 10px;
}
.MaxPlayer .albumactions img{
  object-fit: cover;
  object-position: center;
  width: 100px;
  height: 100px;
  border-radius: 3px;
  transition: 0.2s;
  cursor: pointer;
}
.MaxPlayer .albumactions img:hover{
  opacity: 0.7;
}
.MaxPlayer .albumactions .btns button{
  display: block;
  width: 100%;
  box-shadow: none!important;
  height: 37px;
  border-radius: 3px!important;
  position: relative;
  min-width: 130px;
}
.MaxPlayer .albumactions .btns button a{
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

}
.MaxPlayer .albumactions .btns button:first-child{
margin-bottom: 8px;
background-color: #212121!important;
}
.MaxPlayer .moretracks{
  padding-bottom: 150px;
}
.card005{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  height: 90px;
  border-bottom: 1px solid #282828;
}


.card005 .trackdetails .tracktitle{
  color: #fff;
}

.card005 .lft{
  display: flex;
  align-items: center;
  width: 74%;
}
.card005 .lft .trackdetails{
width: 100%;
}
.card005 .lft .artcover{
  width: 70px;
  height: 70px;
  min-width: 70px;
  border-radius: 3px;
  margin-right: 10px;
}
.card005 .rgt .playbtn{
  background-color: #212121;
  transition: 0.3s;
  width: 70px;
  height: 70px;
  min-width: 70px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 6px;
  overflow: hidden;
}
.card005 .rgt .playbtn img{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;

}
.card005.active .rgt .playbtn {
  border: 1px solid #DF1233;

}
.card005 .rgt .playbtn svg{
  position: relative;
  z-index: 1;
  font-size: 26px;
  color: #DF1233;
  fill: #DF1233;
}
.card005 .trackdetails .tracktitle p{
  font-weight: 600;
  display: block;
  margin: 0px;
  cursor: pointer;
  color: #fff;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.card005 .trackdetails .tracktitle p a{
  font-size: inherit;
  color: inherit;
}
.card005.active .trackdetails .tracktitle p:first-child{
  color: #DF1233;
}
.card005 .trackdetails .tracktitle p:first-child{
  color: #fff;
  margin-bottom: 2px;
}
.card005 .trackdetails .tracktitle p time{
  font-size: 11px;
  color: inherit;
  margin-left: 5px;
  font-style: italic;
  white-space: nowrap;
}
.card005 .trackdetails .tracktitle p:last-child{
  font-weight: 400;
  color: #A5A1A1;
  display: unset;
  
}
.card005 .trackdetails .tracktitle p:last-child b{
  color: #DF1233;
  font-weight: 400;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
}
.card005 .trackdetails .tracktitle p:last-child b a:hover{
  text-decoration: underline;
}
.card005 .trackdetails .reacticons{
  display: flex;
  margin-top: 5px;
}
.card005 .trackdetails .reacticons .icon{
  display: flex;
  align-items: center;
  background-color: transparent;
  border-radius: 6px;
  min-height: 24px;
  cursor: pointer;
  padding-right: 10px;
}
.card005 .trackdetails .reacticons .icon a {
  display: inherit;
  align-items: inherit;
}
.card005 .trackdetails .reacticons .icon:active{
  background-color: rgb(255 255 255 / 5%);
}
.card005 .trackdetails .reacticons .icon p{
  font-weight: 500;
  font-size: 10px;
  margin: 0px;
  margin-left: 3px;
  color: #7d7d7d;
}

.card005 .trackdetails .reacticons .icon .MuiCircularProgress-root{
  width: unset!important;
  height: unset!important;
}
.card005 .trackdetails .reacticons .icon .MuiCircularProgress-root svg{
  margin: 0px auto;
  width: 18px;
  height: 18px;
}


.card005 .trackdetails .reacticons .icon:first-child{
  padding-left: 0px;
}

.card005 .trackdetails .reacticons .icon svg{
  font-size: 15px;
  fill: #fff;
  color: #fff;
  opacity: 0.3;
  
}
.card005 .trackdetails .reacticons .icon.active svg{
  color: #DF1233;
  fill: #DF1233;
  opacity: 1;
}

/* Upload Music */
.uploadcomplete  {
  padding-bottom: 100px;
}
.uploadcomplete .banner{
  width: 100%;
  padding: 10px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #e01233;
}
.uploadcomplete .banner .wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 0px;
}
.uploadcomplete .banner .bgimg{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  left: 0px;
  top: 0px;
  filter: blur(8px);
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  transform: scale(1.3);
}
.uploadcomplete .banner .fademask{
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.6;
}
.uploadcomplete .banner .lft{
position: relative;
z-index: 1;
}
.uploadcomplete .banner .lft img{
  height: 140px;
  width: 140px;
  object-fit: cover;
  object-position: center;
  border-radius: 3px;

}
.uploadcomplete .banner .rgt button{
  background-color: #DF1233!important;
  width: 110px;
  color: #fff!important;
  height: 39px;
}


.uploadcomplete .contain{
  padding: 10p 0px;
}

.uploadcomplete .contain h2{
  font-size: 13px;
  color: #fff;
}
.uploadcomplete .contain .htitle p{
  font-weight: 600;
}

.uploadcomplete .contain .details{
  margin-bottom: 20px;
}

.uploadcomplete .contain .shareplatforms{
  margin-bottom: 20px;
}

.uploadcomplete .contain .shareplatforms .card06{
  border: 1px solid transparent;
  height: 146px;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  margin-bottom: 15px;
}

.uploadcomplete .contain .shareplatforms .card06 svg{
  font-size: 35px;
  color: #fff;
}
.uploadcomplete .contain .shareplatforms .card06 p{
  color: #fff;
  width: 100%;
  text-align: center;
  display: block;
  margin: 0px;
}
.uploadcomplete .contain .shareplatforms .card06:hover svg{
  color: #DF1233;
  fill: #DF1233;
}
.uploadcomplete .contain .details p{
  margin: 0px;
  margin-bottom: 7px;
}
.uploadcomplete .contain .details p a{ 
  color: #DF1233!important;
  font-weight: 500;
}
.uploadcomplete .myform{
  padding: 0px;
}
.uploading-container{
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  z-index: 99999;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.uploading-container .bgimg{
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
  
  transform: scale(1.3);
}


.uploading-container .songart{
  height: 200px;
  width: 200px;
  margin: 0 auto;
  margin-bottom: 34px;
  border-radius: 3px;
  overflow: hidden;
  background-color: #000;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploading-container .songart img{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.uploading-container .fademask{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
}
.uploading-container .contain{
  position: relative;
  z-index: 9;
  padding: 10px;
  text-align: center;
  margin-top: -80px;
}
.uploading-container .contain p{
margin-top: 10px;
color: #fff;
font-size: 14px;
}
.uploading-container .contain button{
background-color: #DF1233!important;
width: 100%;
height: 45px;
border-radius: 50px;
color: #fff;
}

.uploading-container .MuiLinearProgress-dashed{
left: 0px;
}
.imgcrop{
  display: block;
  overflow: hidden;
  animation-name: fadefx;
  animation-duration: 0.4s;
  animation-iteration-count: initial;
  transition: .4s;
  position: relative;
}
.imgcrop img{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius:6px;

}
@keyframes fadefx{
  0%{opacity: 0;}
  100%{transform: 1;}
}

.uploadmusic .upload-file{
  background-color: transparent;
  display: flex;
  align-items: center;
  border: 1px solid #282828;
  border-radius: 6px;
  overflow: hidden;
  transition: 0.2s;
  cursor: pointer;
  padding: 0px;
  width: 100%;
  text-align: left;
  line-height: unset;
}
.uploadmusic .upload-file label{
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
}
.uploadmusic .upload-file:hover{
  border: 1px solid #282828;
}
.uploadmusic .upload-file:active .lft{
  opacity: 0.7;
}
.uploadmusic .upload-file.active{
 border: 1px solid #DF1233;
}
.uploadmusic .upload-file input{
  position: absolute;
  visibility: hidden;
}
.uploadmusic .upload-file .lft{
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 115px;
  background-color: #282828;
  margin-right: 10px;
  padding: 10px;
}
.uploadmusic .upload-file .rgt{
  width: 100%;
  padding-right: 10px;
}
.uploadmusic .upload-file p{
color: #fff;
margin: 0px;
font-weight: 500;
}
.uploadmusic .upload-file .rgt p{
  font-weight: 400;
  display: block;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  color: #ccc;
}



.uploadmusic form{

  padding-bottom: 100px;
}
.uploadmusic .albumtitle{
  color: #fff;
  min-height: 43px;
  display: flex;
  align-items: center;
  background-color: #101010;
  margin-bottom: 20px;
  padding: 0px 10px;
  border-radius: 6px;

}
.uploadmusic .albumtitle p{
  margin: 0px;
  min-width: 78px;
}
.uploadmusic .albumtitle input{
margin-left: 6px;
color: #fff;
width: 100%;
border: 0px;
background-color: transparent;
}
.uploadmusic .chooseupload{
  display: flex;
}
  .uploadmusic .chooseupload a{
    width: 100%;
}
.uploadmusic .chooseupload button{
  min-height: 104px;
  height: 18vh;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 10px;
  display: block;
  border: 1px solid #282828;
  color: #fff;
}

.uploadmusic .chooseupload button:hover{
  background-color: #101010!important;
}
.uploadmusic .chooseupload a:nth-child(2),
.uploadmusic .chooseupload button:nth-child(2)
{
  margin-left: 15px;
}


  .uploadmusic .chooseupload button p{
  display: block;
  font-weight: 600;
  color: #fff;
  margin: 0px;
}
  .uploadmusic .chooseupload button svg{
    width: 30px;
    height: 30px;
}
.uploadtips{
  margin-bottom: 30px;
}
.uploadtips .list{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.uploadtips .num{
  width: 38px;
  border-radius: 50px;
  font-weight: 600;
  height: 38px;
  min-width: 38px;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 600;
  justify-content: space-around;
}
.uploadtips p{
  margin: 0px 0px 0px 10px;
}

.uploadmusic .uploadcover{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.ant-tooltip {
  display: none!important;
}





.imgcrop button{
  padding: 0px;
}
.uploadmusic .uploadcover .titles{
  margin-left: 15px;
  width: 30%;
}
.uploadmusic .uploadcover .titles p{
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0px;
}
.uploadmusic .uploadcover .titles p:first-child{
  color: #fff;
}
.uploadmusic .uploadcover .titles p:nth-child(2){
  font-weight: 400;
  margin-top: 5px;
  text-transform: unset;
}
.uploadmusic form .fields{
  padding: 10px 0px;
}


.myform .MuiFormControl-root label{
  font-weight: 600;
  transform: translate(0, 24px) scale(1);
  font-size: 12px;
}
.myform .MuiFormControl-root .MuiInputLabel-shrink{
  transform: translate(0, 15px) scale(1);

}
.myform .MuiInputBase-input{
  font-weight: 500;
  padding: 10px 15px 10px 15px;
  height: 15px;
  border-radius: 0px!important;
}
.myform textarea{
  min-height: 115px;
  max-height: 115px;
  padding: 0px!important;
  margin-top: -10px;
}
.myform .MuiFormControl-root label{
  top: -5px;
  left: 14px
}
.myform .MuiInputBase-root:before{
  border-bottom: 1px solid transparent!important;
}
.mycheckboxes{
  border-top: 1px solid #282828;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
}
.mycheckboxes .mycheck{
  display: flex;
  align-items: center;
  margin-top: 10px;

}
.mycheckboxes .mycheck .circle{
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #212121;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
 .mycheckboxes .mycheck .circle span{
    width: 50%;
    height: 50%;
    background-color: #DF1233;
    border-radius: 50%;
    display: block;
    transition: 0.2s;
    opacity: 0;
    transform: scale(0);
}
.mycheckboxes .mycheck.active .circle span{
  opacity: 1;
  transform: scale(1);
}
.mycheckboxes .mycheck p{
  color: #959595;
  font-weight: 600;
  margin: 0px;
}
.flexlabel .lft p{
  margin-bottom: 0px;
}
.addlyrics{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
  margin-bottom: 18px;
}


.addlyrics p{
  margin: 0px;
}
.addlyrics .lft p:last-child{
  margin-bottom: 0px;
  font-weight: 400;
  color: #DF1233;
}

.addlyrics button{
  background-color: #212121!important;
  color: #fff;
  box-shadow: none!important;
  height: 41px;
  min-width: 100px;
}



.addlyrics button{
  color: #fff;
  margin-bottom: 0px;
}
.uploadmusic form .defaultBTN{
  margin-top: 10px;
}
.uploadmusic form hr{
  border: 0px;
  border-bottom: 1px solid #282828;
  margin-bottom: 20px;
  margin-top: 0px;
}
.uploadmusic .trackupload{
  margin-bottom: 10px;
  border-bottom: 1px solid #2c2c2c;
 
}
.uploadmusic .trackupload.active{
  border-bottom: 1px solid #DF1233;
}
.uploadmusic .sections .trackupload:last-child{
  margin-bottom: 20px;


}
.uploadmusic .trackupload .upload{
  display: flex;
  align-items: center;
  border-radius: 3px;
  
}

.uploadmusic .trackupload .upload .num{
  font-weight: 600;
  margin: 0px;
  height: 38px;
  min-width: 38px;
  display: flex;
  align-items: center;
  width: 38px;
  border-radius: 50%;
  justify-content: center;
  color: #fff;
  background-color: #DF1233;
 
}
.uploadmusic .trackupload .upload input{
  border: 0px;
  width: 100%;
  height: 38px;
  padding: 10px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #2c2c2c;
  border-radius: 50px;
  margin-left: 10px;
  padding-left: 20px;
  transition: 0.2s;
}
.uploadmusic .trackupload .upload.active input{
  border: 1px solid #DF1233;
}
.uploadmusic .trackupload .upload input:focus{
  background-color: #101010;
}
.uploadmusic .trackupload .uploaded{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
}

.uploadmusic .trackupload .uploaded .lft span{
  color: #aaa;
  font-size: 12px;     
  margin-right: 5px;
}
.uploadmusic .trackupload  .uploaded .lft span.error{
  color: #DF1233;
}
.uploadmusic .trackupload  .uploaded button{
  width: 33px;
  min-width: 33px;
}
.ant-modal-mask{
  z-index: 99999!important;
}
.ant-modal-wrap{
  z-index: 99999999!important;
}
.ant-modal{
  top: 0px!important;
  width: 100%!important;
  max-width: 100%!important;
  margin: 0px!important;
}
.anticon{
  width: 39px!important;
  height: 38px!important;
  min-width: 15px!important;
  display: inline-flex!important;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.ant-modal .ant-modal-footer button {
  width: 100%!important;
  border-radius: 50px;
  height: 45px;
  font-weight: 600;
  border: 0!important;
  box-shadow: none!important;
}
.ant-modal .ant-modal-footer button span{
  color: #fff!important;
}
.ant-modal .img-crop-control{
  width: 80%!important;
}
.ant-modal .ant-modal-content {
  padding-top: env(safe-area-inset-top);
  border-radius: 6px!important;
  overflow: hidden!important;
  box-shadow: unset!important;
  background-color: #181818!important;
  padding: 0px 5px 20px 5px;
}
.ant-modal .ant-modal-footer {
  display: flex;
  padding: 15px 15px!important;
  flex-direction: row-reverse;
}
.ant-modal .ant-modal-footer button {
  transition: 0.2s!important;
  margin: 0px!important;
  border: 0px!important;
}
.ant-modal .ant-modal-footer button:last-child{
  margin-right: 15px!important;
}
.ant-modal .img-crop-control button {

  width: 45px!important;
  height: 45px!important;
  border-radius: 50px!important;
  margin: 0px 10px!important;
  line-height: 0px;
}
.ant-modal .ant-modal-title{
  font-size: 13px;
  font-weight: 500;
}
.ant-modal .ant-modal-header {
  height: 42px;
  padding: 0px!important;
  display: flex;
  align-items: center;
  margin: 15px;
}
.ant-modal .ant-modal-body {
  padding: 0px 15px;
  padding-bottom: 15px;

}
.ant-modal .ant-modal-close{
  display: none!important;
}
.ant-modal .ant-modal-close:active{
  background-color: #e7e7e740;
}
.ant-modal svg {
  font-size: 16px;
}
/* Profile */
.userbanner{
  min-height: 180px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  padding-top: 0px;
  background-color: #212121;
}

.userbanner .addbio{
  font-weight: 600;
  margin-top: 20px;
  display: block;
  
  color: #f30027!important;
}

.userbanner .background{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 2;
  opacity:0.7;
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  filter: saturate(2);
  -webkit-filter: saturate(2);
  -moz-filter: saturate(2);
  -o-filter: saturate(2);
  -ms-filter: saturate(2);
  transition: 0.6s;
}


.userbanner .flexdv{
  padding: 15px 15px 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 3;
}
.userbanner .lft {
  color: #fff;
}
.userbanner .lft .titles01{
  font-weight: 600;
  margin-bottom: 5px;
}
.userbanner .lft .titles01 p{
  font-size: 15px;
  display: block;
  margin: 0px;
  line-height: 20px;
  color: #fff;
  text-shadow: 0px 1px 1px rgb(0 0 0 / 50%);
}
.userbanner .lft .titles01 p:nth-child(2){
  color: #fff;
  margin: 0px;
  font-weight: 400;
  opacity: 0.7;
  font-size: 13px;
  text-transform: capitalize;
}

.userbanner .lft .titles02{
  margin-bottom: 0px;
  color: #fff;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}
.userbanner .lft .titles02 .genres{
  text-transform: capitalize;
}
.userbanner .lft .titles02::before{
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: rgb(255 255 255 / 10%);
  margin: 0 auto;
  margin-bottom: 10px;
}
.userbanner .lft .titles02 a{
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  color: #DF1233!important;
}

.userbanner .icons{
  display: flex;
  justify-content: center;
  max-width: 700px;
  margin: 3px auto 12px auto;
}
.userbanner .icons button{
  min-width: 43px;
  height: 43px;
  width: 43px;
  border-radius: 50px;
  margin-right: 6px;
  margin-bottom: 5px;
  color: #fff;
  background-color: rgb(255 255 255 / 9%);
  border: 1px solid transparent;
  position: relative;
}
.userbanner .icons button:hover{
  background-color: rgb(255 255 255 / 20%);
}
.userbanner .icons > button:first-child{
  width: 50%;
 
}
.userbanner .icons > button a{
  width: 100%;
  position: absolute;
  top: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.userbanner .icons .active{
  border: 1px solid rgb(255 255 255 / 10%);
  background-color: transparent!important;
  color: rgb(255 255 255 / 70%);
}

.userbanner .icons > button:first-child .MuiCircularProgress-root,
.myfollower .rgt button .MuiCircularProgress-root{
  width: 14px!important;
  height: 14px!important;
  margin-left: 10px;
}


.userbanner .icons button:first-child .MuiButton-label{
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userbanner .icons button svg{
  color: #fff;
  font-size: 20px;
}
.usericon.desktop .ppimg{
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: 20px;
  object-fit: cover;
  object-position: center;
  display: flex;
  align-items: center;
  font-size: 32px;
  font-weight: 400;
  justify-content: center;
  transition: 0.2s;
  margin: 0 auto;
  margin-bottom: 15px;
  cursor: pointer;
}
.usericon.desktop .ppimg:hover{
  transform: scale(1.1);
}
.userbanner .rgt .ppimg{
  width: 110px;
  min-width: 110px;
  height: 110px;
  border-radius: 50%;
  margin-left: 20px;
  object-fit: cover;
  object-position: center;
  display: flex;
  align-items: center;
  font-size: 26px;
  font-weight: 400;
  justify-content: center;
  transition: 0.2s;
  text-transform: uppercase;
  
}
.userbanner .contain,
.userbanner .kpis,
.userbanner .kpisactionbtns{
  position: relative;
  z-index: 4;
}
.userbanner .contain{
  z-index: 9;
}
.userbanner .kpis{
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.userbanner .kpis{
  align-items: flex-end;
}
.userbanner .kpis .kpi, .userbanner .kpis .kpi a, .userbanner .kpis .kpi button{
    width: 100%;
}
.userbanner .kpis .kpi button{
  padding: 5px 16px;
  border-radius: 0px;
  color: #fff;
  display: block;
  background-color: transparent;
}
.userbanner .kpis .kpi button::after{
  position: absolute;
  content: "";
  right: 0px;
  height: 60%;
  width: 1px;
  background-color: rgb(255 255 255 / 10%);
  top: 0px;
  bottom: 0px;
  margin: auto;
}
.userbanner .kpis .kpi:last-child button::after{
  width: 0px;
}
.userbanner .kpis .kpi p{
  display: block;
  text-align: center;
  line-height: 22px;
  margin: 0px;
}
.userbanner .kpis .kpi p:nth-child(1){
  font-weight: 600;
  font-size: 15px;
}
.userbanner .kpis .kpi p:nth-child(2){
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px;
  opacity: 0.7;
}
.profilebanner{
  overflow: hidden;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profilebanner .imgcrop{

}
.profilebanner .imgcrop .ant-upload.ant-upload-select,
.profilebanner .imgcrop .ant-upload-list-item.ant-upload-list-item-error,
.profilebanner .imgcrop .ant-upload-list-item.ant-upload-list-item-error::before,
.profilebanner .imgcrop .ant-upload-list-item-thumbnail
{
  border-radius: 50%!important;
}
.profilebanner  .userpp{
  position: relative;
  width: 140px;
  min-width: 140px;
  height: 140px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
}
.profilebanner .userpp .icon{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.3s;
}
.profilebanner .userpp .icon svg{
  font-size: 32px;
  color: #df1233;
}
.profilebanner .userpp .icon:hover{
  background-color: rgb(0 0 0 / 67%);
  opacity: 1;
}
.profilebanner .ppimage{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #212121;
  font-size: 38px;
}


.htitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  margin-bottom: 10px;
}
.htitle p::after{
  content: unset;
  display: none!important;
}
.htitle p{
  display: block;
  margin: 0px;
  text-transform: uppercase;

}
.htitle p a{
  padding: 5px 0px;
  height: 30px;
  border-radius: 6px;
  border: 1px solid #282828;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  font-size: 11px;
  min-width: 70px;
}
.htitle p a:hover{
  color: #DF1233;
  text-decoration: underline;
}
.htitle .lft p:first-child{
  color: #fff;
  font-weight: 600;
}
.htitle .lft p::after{
    content: "";
}

.htitle p.lastsync{
  font-weight: 300;
  text-transform: capitalize;
}

.htitle p.lastsync time{
  font-weight: inherit;
  font-size: inherit;
  color: inherit;
}
.htitle button{
  border-radius: 50px;
  height: 36px;
  min-width: 75px;
  text-transform: none;
}
.htitle button.transparent{
 background-color: transparent!important;
}
.htitle button.transparent{
 color: #ccc;
 display: flex;
 align-items: center;
}
.htitle button.transparent svg{
margin-left: 10px;
}

.htitle .lft p .platform{
  font-weight: 400;
  font-size: 9px;
  font-style: italic;
  color: #A5A1A1;
}
.myfilter{
  padding: 15px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.myfilter .lft p{
margin: 0px;
}
.myfilter .lft p:first-child{
  font-weight: 600;
  margin-bottom: 3px;
  text-transform: uppercase;
  color: #fff;
}
.myfilter .rgt button{
  border-radius: 6px;
  height: 36px;
  min-width: 70px;
  text-transform: none;
  color: #fff;
  padding: 0px 10px;
  text-transform: uppercase;
  border: 1px solid #282828;
}
.myfilter .rgt button:hover{
  background-color: #101010!important;
}
.myfilter .rgt button svg{
  margin-left: 10px;
}
/* Album */
.albumcontainer, .card05container,
.vertical-container{
  width:100%;
  overflow-x:scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling:touch;
}

.swiper .albumcard{
  margin-bottom: 0px!important;
} 

.albumcard{
  background-color: #212121;
  cursor: pointer;
  overflow: hidden;
  border-radius: 6px;
  display:inline-block;
  transition: 0.1s;
  margin-right: 10px;
  margin-bottom: 20px;
  width: 100%;
}


.albumcard .contents{
  width: 100%;
}

.vertical-container .albumcard{
  width: unset;
  margin-bottom: 0px;
}
.albumcard .contents .songcover{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
}

.vertical-container .albumcard .contents{
  width: 200px;
}

.albumcard .contents .songcover .artcover{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;

}

.albumcard .contents .songcover svg{
  z-index: 2;
  color: #fff;
  transition: 0.3s;
  position: absolute;

  margin: 10px;
  width: 30px;
  height: 30px;
  bottom: 0px;
  left: 0px;
  border-radius: 50%;
  padding: 4px;
  color: #fff;
  background-color: #212121;
}
.albumcard .contents .songcover svg.active{
  background-color: #df1233;
}
.albumcard .contents .details{
    padding: 12px 15px;
    text-align: left;
}
.albumcard .contents .details p{
  display: block;
  margin: 0px;
  font-weight: 600;
  line-height: 18px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-bottom: 2px;
  color: #fff;
}
.albumcard .contents .details p time{
  font-size: 11px;
  font-style: italic;
  color: inherit;
  margin-left: 5px;
}
.albumcard .contents .details p a{
  font-size: inherit;
  color: inherit;
}

.albumcard .contents .details p:nth-child(2){
  font-weight: 400;
  color: #b1b1b1;
}
.albumcard .contents .songcover:hover{
  opacity: 0.7;
}
.albumcard .contents .details p a:hover{
  text-decoration: underline;
}
.card14{
  border-bottom: 1px solid #2c2c2c;
  cursor: pointer;
}

.card14 .details{
  transition: 0.2s;
  padding: 5px 0px 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card14 .details:hover{
  background-color: #121212;
}
.card14 .contain{

}
.card14 .contain .tracklist{
  color: #DF1233;
  font-weight: 600;
  border-top: 1px solid #2c2c2c;
  padding-top: 12px;
  margin: 0px;
}
.card14 .details:active {
  background-color: #212121;
}

.card14 .lft{
  display: flex;
  align-items: center;
}
.card14 .lft .artcover{
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  background-color: #212121;
  border-radius: 2px;
}
.card14 .lft .title{
  color: #fff;
}
.card14 .rgt{
  display: flex;
  align-items: center;
  justify-content: center;
}
.card14 .rgt svg{
  color: #fff;
  transform: rotate(0deg);
  transition: 0.4s;
}
.card14.active .rgt svg{
  transform: rotate(180deg);
}
.card13{
  padding: 5px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #2c2c2c;
}

.card13 .lft .title{
  color: #ccc;
}
.card13 .lft .title:hover{
  color: #DF1233
}
.card13 .rgt{
  display: flex;
  justify-content:right;
}
.card13 .rgt button{
  background-color: #212121!important;
  margin-left: 5px;
  color: #fff;
}



/* card05 */


.card05{
  width: 160px;
  overflow: hidden;
  border-radius: 6px;
  display:inline-block;
  transition: 0.1s;
  margin-right: 10px;
  position: relative;
  
}

.card05 .contents .loadmore{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
}
.card05:active{
  opacity: 0.7;
}

.card05 .contents{
  width: 100%;
  height: 210px;
  position: relative;
}


.card05 .contents .artcover{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: 0.5s;
}

.card05 .fademask{
  position: absolute;
  height: 100%;
  bottom: 0px;
  opacity: 1;
  left: 0px;
  width: 100%;
  transform: scale(1.5);
}
.card05 .play{
  position: absolute;
  height: 100%;
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
.card05 .play button{
width: 43px;
height: 43px;
display: flex;
align-items:center;
justify-content: center;
border-radius: 50px;
border: 0px;
position: relative;
top: -6px;
}

.card05 .play button svg{
font-size: 30px;
}
.card05 .contents .details{
    padding: 15px 10px;
    text-align: left;
    position: absolute;
    bottom: 0px;
    left: 0px;
    color: #fff;
    width: 100%;
    z-index: 1;

}
.card05 .contents .details span{
  display: block;
  font-weight: 600;
  font-size: 12px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;

}
.card05 .contents .details span:nth-child(2){
  font-weight: 400;
  margin-top: 3px;
  font-size: 11px;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}
.card05 .contents .details span time{
  font-weight: inherit;
  font-size: inherit;
  
}
/* Followers */

.myfollower{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  
}

.myfollower .lft{
    
  display: flex;
  align-items: center;
  flex: 1;
}
.myfollower .lft .userpic{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  object-position: center;
  font-size: 14px;
  
}
.myfollower .lft svg{
  width: 45%;
  height: 45%;
}
 .myfollower .details{
    margin-left: 10px;
}
.myfollower .details p{
  display: block;
  line-height: 18px;
  margin: 0px;
}
.myfollower .details p:first-child{
  font-weight: 600;
  color: #fff;
}

.myfollower .rgt button{
  font-weight: 500;
  min-width: 90px;
  padding: 7px 10px;
  box-shadow: unset;
  display: flex;
  align-items: center;
  justify-content: center;

}
.myfollower .rgt button.active {
  background-color: #282828!important;
  color: #fff;
}
.myfollower .rgt button p{
  margin: 0px;
  color: #fff;
  text-transform: capitalize;
}


/* Notifications */

.mynot{
  display: flex!important;
  justify-content: space-between!important;
  align-items: center!important;
  padding: 10px 0px!important;
  position: relative!important;
  color: #fff!important;
  text-align: left!important;
  width: 100%!important;
  text-transform: none!important;
  border-bottom: 1px solid #282828!important;
  background-color: transparent!important;  
  border-radius: 0px!important;
  letter-spacing: 0px!important;
}

.mynot .lft{
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  text-decoration: initial;
  text-overflow: ellipsis;
  transition: .2s;
  white-space: nowrap;
  width: 100%;
}
.mynot .lft .userpic{
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  object-position: center;
  background-color: #212121;
  margin-right: 10px;
}
.mynot .lft .userpic p{
  transform: scale(1.2);
  color: #fff;
  font-weight: 400;
}
.mynot .details{
  width: calc(100% - 70px);
}
.mynot .lft .details svg{
  color: #827e85;
}
.mynot.active .details svg{
  color: #DF1233;
  fill: #DF1233;
}
.mynot .lft .details p{
  display: block;
  line-height: 17px;
  font-weight: 500;
  margin: 0px;

}
.mynot .lft .details p:first-child{
  color: #fff;
}
.mynot .lft .details p:last-child{
  font-weight: 400;
  display: block;
  margin-top: 4px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mynot .lft .details p a {
  color: inherit;
}

.mynot .rgt{
  text-align: right;
}
.mynot .rgt time{
  font-size: 9px;
  text-transform: uppercase;
  opacity: 0.6;
  white-space: nowrap;
  font-weight: 300;
  padding: 5px;
  background: #212121;
  border-radius: 6px;
  display: flex;
  height: 30px;
  align-items: center;
  justify-content: center;
  line-height: 0px;
  min-width: 70px;
}
.mynot .rgt button{
  font-weight: 500;
  font-size: 10px;
  padding: 10px 25px;
  box-shadow: unset;
}

.mynot .lft .details svg{
  font-size: 16px;
  margin-right: 5px;
  position: relative;
  top: 4px;

}

/* Notifications Settings*/

.notscontain .mynot{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  position: relative;
  padding-left: 0px;
}

.notscontain .mynot .lft{
    
  display: flex;
  align-items: center;

  position: relative;
}
.notscontain .lft .MuiAvatar-root{
  width: 50px;
  height: 50px;
}

.notscontain .details p{
  display: block;
  font-weight: 600;
  margin: 0px;
}
.notscontain .details p:last-child{
  font-weight: 400;
}
.notscontain .rgt{
  font-size: 12px;
}
.notscontain .rgt button{
  font-weight: 600;
  padding: 10px 25px;
  box-shadow: unset;
  background-color: #DF1233;
  font-size: 12px;
}
.notscontain .rgt button{
  color: #fff;
}
.notscontain .mynot .MuiCheckbox-root svg{
  font-size: 25px;
}
/* Recommended Users */

.card002{
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  transition: 0.2s;
  margin-right: 10px;
  min-height: 220px;
  padding: 10px 10px 10px 10px;
  border-radius: 6px;
  margin-bottom: 20px;
  background-color: #212121;
}


.vertical-container .card002{
  width: 170px;
  margin-bottom: 0px;
}
.card002 .ppimg{
  width: 88px;
  height: 88px;
  margin: 6px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  color: #fff!important;
  background-color: #181818;
  text-transform: uppercase;
  
}
.card002 .ppimg img{
  width: 100%;
  height: 100%;
  transition: 0.2s;
  object-fit: cover;
  object-position: center;
}
.card002 .ppimg svg{
  color: #fff;
  fill: #fff;
}
.card002 .ppimg img:active{
  opacity: 0.6;
}
.card002 .details .titles{
  max-width: 80%;
  margin: 10px auto;
  margin-bottom: 10px;
}
.card002 .details .titles p{
  display: block;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin: 0px;
  color: #fff;
  transform: scale(0.97);
}
.card002 .details .titles p a{
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
}
.card002 .details .titles p a:hover{
  opacity: 0.6;
}
.card002 .details .titles p:nth-child(2){
  font-weight: 400;
  margin: 4px;
  opacity: 0.6;
}
.card002 .details .titles p:nth-child(2) a{
  color: inherit;
  font-weight: inherit;
  text-transform: capitalize;
}
.card002 .details button{
  width: 90%;
  padding: 9px!important;
  border-radius: 6px;
  height: 40px;
  white-space: nowrap;
  font-size: 11px!important;
  background-color: #282828!important;
  color: #fff!important;
  font-weight: 600;
}
.card002 .details button.active{
  background-color: #DF1233!important;
}
.card002 .details button .MuiCircularProgress-root{
  width: 14px!important;
  height: 14px!important;
  margin-left: 10px;
}
.card002 .details button svg{
  color: #fff;
}
.card002 .details .followers{
  margin: 0px;
  margin-top: 7px;
  font-size: 12px;
}
.myloader{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 101px;
}
/* Logging Out */
.logging_out{
  height: 100vh;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  margin: 0 auto;
}
/* Logging Out */

.logging_out div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.logging_out .MuiCircularProgress-root{
  width: 30px!important;
  height: 30px!important;
  min-width: 30px;
}
.logging_out p{
  margin: 0px;
  margin-left: 10px;
}
/* Form */
form .myerr{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  padding: 0px;
  margin: 10px 0px 15px 0px;

}
form .myerr p{
  margin: 0px;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
form .myerr p a{
  color: #DF1233;
  margin-left: 10px;
  text-decoration: underline;
}
form .myerr .MuiCircularProgress-root{
  margin-left: 10px;
}
form .myerr .MuiCircularProgress-root{
  width: 18px!important;
  height: 18px!important;
  min-width: 18px!important;

}

form .myerr button {
  font-weight: 500;
  text-transform: capitalize ;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent!important;
  color: #fff;
}

.MaxPlayer .progressBar{
  --bar-bg: rgb(255 255 255 / 7%);
  --seek-before-width: 0;
  --seek-before-color: rgb(255 255 255 / 80%);
  --knobby: #ffffff;
  --selectedKnobby: #ffffff;
  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  width: 100%;
  height: 2px;
  outline: none;
  position: relative;
  margin: 0px 10px;
}
.MaxPlayer .timer{
  cursor: pointer;
  color: #fff;
}
/* Progress Bar Safari */
.MaxPlayer .progressBar::-webkit-slider-runnable-track{
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 2px;
  outline: none;
}
/* Progress Bar Firefox */
.MaxPlayer .progressBar::-moz-range-track{
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 2px;
  outline: none;

}
.MaxPlayer .progressBar::-moz-focus-outer{
  border: 0;
}
/* Progress Bar chrome and safari */
.MaxPlayer .progressBar::before{
  content: '';
  height: 2px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
}

.progressBar::-moz-range-progress{
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 2px;
}
/* Knobby Chrom and Safari */
.MaxPlayer .progressBar::-webkit-slider-thumb{
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: -2px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
  top: -5px;
  transition: 0.2s;
  opacity: 1;
  transform: scale(1);

}
/* Dragging Knobby chrome and safari */
.MaxPlayer .progressBar:active::-webkit-slider-thumb,
.MaxPlayer .progressBar:hover::-webkit-slider-thumb{
  transform: scale(1.4);
  background: var(--selectedKnobby);
}
/* Knobby Firefox */
.MaxPlayer .progressBar::-moz-range-thumb{
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  top: -5px;
  z-index: 3;
  box-sizing: border-box;
  opacity: 1;
  transform: scale(1);
}

/* Dragging Knobby firefox */
.MaxPlayer .progressBar:active::-moz-range-thumb,
.MaxPlayer .progressBar:hover::-moz-range-thumb{
  background: var(--selectedKnobby);
}
.MaxPlayer .timer:active .progressBar::-moz-range-thumb{
  transform: scale(1.3);
}

.MaxPlayer .timer:active .progressBar::-webkit-slider-thumb{
  transform: scale(1.3);
}
.browse{
  padding-top: 129px;
  padding-bottom: 120px;
}

/* Search Suggestions */
.searchSuggestions{
  padding-top: 60px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;
    height: 100vh;
    font-size: 12px;
}
.searchSuggestions .contain{
padding-top: env(safe-area-inset-top);
}
.searchSuggestions button{
  display: block;
  width: 100%;
  text-align: left;
  padding: 10px;
  border-radius: 0;
  text-transform: unset;
  font-weight: 400;
}
.searchSuggestions button span{
  font-size: 12px;
  font-weight: 400!important;
}
.searchSuggestions button:first-child{
  font-weight: 600;
}
.MuiDrawer-root .MuiPaper-root{
background-color: transparent;
}
.MuiDrawer-root{
  z-index: 9999!important;
}
.myModal{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0px;
  left: 0px;
  z-index: 9999999;
}
.myModal .container{
  min-width: 290px;
  max-width: 500px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  animation-name: comeinfx;
  animation-duration: 0.5s;
  animation-iteration-count: initial;
  transition: 0.5s;
  margin: 10px;
  padding: 0px;
  background-color: #181818;
  box-shadow: 0px 0px 50px rgb(0 0 0 / 45%);
}

@keyframes comeinfx{
  0%{opacity: 0;transform: scale(0.9);}
  100%{opacity: 1;transform: scale(1);}
}
.myModal .errIcon {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 13vh;
  min-height: 110px;
}
.myModal .errIcon svg{

  height: 50px;
  width: 50px;
}
.myModal .errbody{
  padding: 20px 20px 30px 20px;
  text-align: center;
}
.myModal .errbody .artcover{

}
.myModal .errbody .artcover a{
  border-radius: 6px;
}
.myModal .errbody .user_avatar a{
  border-radius: 50%;
}
.myModal .errbody .user_avatar p{
  font-size: 30px;
  margin: 0px;
}
.myModal .errbody .artcover a,
.myModal .errbody .user_avatar a{
  text-decoration: unset;
  width: 120px;
  min-width: 120px;
  height: 120px;
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #212121;
  overflow: hidden;
  margin-bottom: 20px;
}
.myModal .errbody .artcover img,
.myModal .errbody .user_avatar img{
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.myModal .btn{

  width: 100%;
  height: 45px;
  border-radius: 50px!important;
  margin-bottom: 10px;
}
.myModal button span{
    font-size: 12px!important;
    font-weight: 600!important;
    text-transform: uppercase;
}
.myModal button .MuiCircularProgress-root{
  width: 18px!important;
  height: 18px!important;
  margin-left: 10px!important;
}
.myModal .header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 25px;
  height: 98px;
}
.myModal .header p{
  font-weight: 600;
  color: #fff;
  margin: 0px;
}
.myModal .header button{
  width: 45px;
  min-width: 45px;
  height: 45px;
  border-radius: 50px;
  display: flex;
  padding: 0px;
}
.myModal .errbody p{
  margin-bottom: 10px;
  font-weight: 400;
  display: block;
  width: 100%;
}
.myModal .errbody p svg{
  font-size: 18px;
  margin-bottom: -4px;
  margin-left: 3px;
  color: #df1233;
}
.myModal .errbody p a{
  font-weight: 600;
  color: #DF1233;
}
.myModal .errbody p a:hover{
  text-decoration: underline;
}
.myModal .errbody p b{
  font-weight: 600;
  color: #fff;
}
.myModal .header button svg{
  width: 25px;
  height: 25px;
}

/* Filter Modal */

.myModal .filter_container{
padding: 25px;
}
.myModal .filter{
  margin-bottom: 25px;
}
.myModal .filter label{
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
  font-size: 11px;
  text-transform: uppercase;
}
.myModal .filter .options{
  display: flex;
  overflow-x: scroll;
}
.myform .selectoptions{
  display: flex;
  overflow-x: scroll;
  margin: 10px 0px 20px 0px;
}
.myModal .filter .options p{
  display: flex;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 50px;
  padding: 10px 20px;
  margin-right: 10px;
  text-transform: none;
  background-color: #212121;
  color: #fff;
  cursor: pointer;
  margin-top: 0px;
}
.myform .selectoptions p{
  display: flex;
  white-space: nowrap;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 45px;
  border-radius: 50px;
  padding: 10px 20px;
  margin: 0px;
  margin-right: 10px;
  text-transform: none;
  background-color: #212121;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}
.myModal .filter .options .active{
  background-color: #DF1233;
}
.myform .selectoptions p.active{
  background-color: #DF1233;
  color: #fff;
  font-weight: 600;
}

.no_data_msg .icon img{
  max-width: 250px;
  margin: 20px auto;
  display: block;
}
.no_data_msg{
  padding: 10px;
  margin: 0 auto;
  max-width: 320px;
  width: 100%;
  min-height: 310px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60vh;
  max-height: 600px;
  transition: 0.7s;
}
.no_data_msg .container{
width: 100%;
}
.no_data_msg p{
    text-align: center;
    font-weight: 500;
}
.no_data_msg p span,
.no_data_msg p a{
  color: #DF1233;
}

.no_data_msg p a{
  font-weight: 600;
}
.no_data_msg .mybtns{
  display: block;
}
.no_data_msg .mybtns button:last-child{
margin-left: 0px;
}
.no_data_msg button.outline{
  background-color: transparent!important;
  color: #fff;
  border: 1px solid #DF1233;
}
.no_data_msg button{
 
  width: 100%;
  background-color: #DF1233!important;
  color: #fff;
  height: 45px;
  border-radius: 50px;

  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}
.loadmorebtn  button{
  width: 100%;
  background-color: #f30027!important;
  color: #fff;
  padding: 10px
}
.loadmorebtn {
 padding: 10px;
}

.loading-album-details{

}
.loading-album-details .p1{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #282828;
  margin-bottom: 20px;
}
.loading-album-details .p2{
  padding-bottom: 10px;
  border-bottom: 1px solid #282828;
}
.loading-album-details .p2 div{
  margin-bottom: 20px;
}
.loading-album-details .p2 div p:first-child{
  width: 30%;
  height: 11px;
  border-radius: 50px;
  margin-bottom: 8px;
}
.loading-album-details .p2 div:last-child{
  margin-bottom: 0px;
}
.loading-album-details .p2 div p:last-child{
  width: 50%;
  height: 11px;
  border-radius: 50px
}

.loading-album-details p{
  background: #353535;
  margin: 0px;
}
.loading-album-details .p1 div:first-child{
  
}
.loading-album-details .p1 div:first-child p{
  width: 90px;
  height: 90px;
  border-radius: 3px;
}
.loading-album-details .p1 div:last-child p{
  height: 37px;
  min-width: 120px;
  border-radius: 3px;
}
.loading-album-details .p1 div:last-child p:last-child{
  margin-top: 8px;
}
/* Skeleton Loader Card */

.anime-skeleton{
  animation: skeleton-loading 0.6s linear infinite alternate;
}
@keyframes skeleton-loading{
  0%{
    opacity: 0.3;
  }
  100%{
    opacity: 1;
  }
}
.card-skeleton{
  margin-bottom: -1px;
  border-top: 1px solid #282828;
  border-bottom: 1px solid #282828;
}
.card-skeleton .header{
  padding: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #2b2b2b;
}
.card-skeleton .header .icon{
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50px;
    background-color: #353535;
    margin-right: 10px;
}
.card-skeleton .header .p{
    width: 100%;
}
.card-skeleton .header .p div{
  width: 50%;
  height: 9px;
  background: #353535;
  display: block;
  margin-bottom: 6px;
  border-radius: 5px;
}
.card-skeleton .header .p div:nth-child(2){
    margin-bottom: 0px;
    width: 40%;
}
.card-skeleton .body{
  border-bottom: 1px solid #2b2b2b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.card-skeleton .body .lf{
  display: flex;
  align-items: center;
  width: 100%;
}

.card-skeleton .body .lf .p{
    width: 100%;
}
.card-skeleton .body .lf .p div{
  width: 70%;
  height: 10px;
  background: #353535;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
}
.card-skeleton .body .lf .p div:nth-child(2){ 
  width: 50%;
  margin-bottom: 0px;
}
.card-skeleton .body .rt{ 
  height: 75px;
  display: block;
  max-width: 75px;
  min-width: 75px;
  border-radius: 3px;
  background: #353535;
}
.card-skeleton .foot { 
  display: flex;
  align-items: center;  
  padding: 10px;
  justify-content: space-between;
}
.card-skeleton .foot .lf{ 
  display: flex;
  align-items: center;  
}
.card-skeleton .foot .rt{ 
  display: flex;
  align-items: center;  
  width: 40%;
  max-width: 140px;
}
.card-skeleton .foot .lf div{ 
  height: 45px;
  width: 36px;
  min-width: 36px;
  border-radius: 5px;
  margin-right: 8px;
  background: #353535;
}
.card-skeleton .foot .rt div{ 
  height: 45px;
  width: 100%;
  min-width: 42px;
  border-radius: 5px;
  margin-left: 5px;
  background: #353535;
}


.comment-skeleton{
  padding: 0px 10px 10px 10px ;
  box-shadow: unset!important;
  border-radius: 0px!important;
  margin-bottom: 15px;
  border-bottom: 1px solid #353535;
}
.comment-skeleton .lft{
  display: flex;
  align-items: center;
}
.comment-skeleton .lft .circle{
  min-width: 43px;
  width: 43px;
  height: 43px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #353535;
}
.comment-skeleton .lft .pars{
    width: 100%;
}
.comment-skeleton .lft .pars p{
  background: #353535;
  height: 10px;
  width: 70%;
  border-radius: 50px;
}
.comment-skeleton .lft .pars p:last-child{
    margin-bottom: 0px;
    width: 30%;
}
.comment-skeleton .body{
  margin-top: 13px;
}
.comment-skeleton .body,
.comment-skeleton .foot{
  margin-left: 53px;

}
.comment-skeleton .body p{
   height: 60px;
   background: #353535;
   width: 90%;
   border-radius: 6px;
}
.comment-skeleton .foot{
  display: flex;

}
.comment-skeleton .foot div{
  height: 35px;
  width: 28px;
  border-radius: 3px;
  background-color: #353535;
  margin-right: 10px;
  
}
.noticard-skeleton{
padding: 10px 0px;
display: flex;
align-items: center;
border-bottom: 1px solid #2b2b2b;
justify-content: space-between;
}
.noticard-skeleton .icon{
  width: 50px;
  min-width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #282828;
  margin-right: 10px;
}
.noticard-skeleton .lft{
  display: flex;
  align-items: center;
  width: 100%;
}
.noticard-skeleton .rgt{
  display: flex;
  align-items: center;
}
.noticard-skeleton .rgt p{
  height: 37px;
  width: 95px;
  border-radius: 5px;
  background-color: #282828;
  margin-bottom: 0px;
}
.noticard-skeleton .details{
  width: 100%;
}
.noticard-skeleton .details p{
  height: 9px;
  width: 70%;
  background-color: #282828;
  display: block;
  border-radius: 50px;
  margin: 0px;
}
.noticard-skeleton .details p:last-child{
    margin-top: 6px;
    width: 30%;
}


.usercard03-skeleton{
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: inline-block;
  transition: 0.2s;
  margin-right: 10px;
  min-height: 220px;
  padding: 15px;
  border-radius: 6px;
  background-color: #212121;
  margin-bottom: 20px;
}
.vertical-container .usercard03-skeleton {
  width: 170px;
}
.usercard03-skeleton .circle{
  width: 80px;
  height: 80px;
  margin: 8px auto;
  background-color: #353535;
  border-radius: 50%;
  margin-top: 0px;
 }

 .usercard03-skeleton .details{
  width: 80%;
  margin: 0 auto;
 }
 .usercard03-skeleton .details p {
    margin: 11px auto;
    height: 10px;
    width: 80%;
    background-color: #353535;
    border-radius: 50px;
 }
.usercard03-skeleton .details p:last-child {
    width: 50%;
 }
.usercard03-skeleton .box{
    height: 38px;
    width: 90%;
    background-color: #353535;
    border-radius: 50px;
    margin: 0 auto;
    margin-top: 10px;
    border-radius: 6px;
 }

 .minplayer-skeleton{
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;

  background-color: #212121;
  border-top: 1px solid #e01233;
  padding: 10px;
  z-index: 999;
  
}
.minplayer-skeleton .contain{
  padding-bottom: env(safe-area-inset-bottom);
  width: 100%;
  display: flex;
  align-items: center;
}
.minplayer-skeleton p{
margin-bottom: 0px;
background-color: #353535;
}
.minplayer-skeleton .lft{
  display: flex;
  align-items: center;
  width: 100%;
}
.minplayer-skeleton .lft div:first-child{
  display: flex;
  align-items: center;
}
.minplayer-skeleton .lft div:first-child p{
  border-radius: 50px;
  height: 50px;
  min-width: 50px;
  width: 50px;

}

.minplayer-skeleton .lft div:first-child p:last-child{
  border-radius: 50px;
  margin-left: 7px;
  width: 37px;
  min-width: 37px;
  height: 37px;
}
.minplayer-skeleton .lft div:last-child{
  width: 100%;
}
.minplayer-skeleton .lft div:last-child p{
  width: 70%;
  height: 8px;
  border-radius: 50px;
  margin-left: 10px;
}
.minplayer-skeleton .lft div:last-child p:last-child{
  width: 40%;
  margin-top: 5px;
}
.minplayer-skeleton .rgt p{
  width: 48px;
  height: 48px;
  border-radius: 3px;
}
.card05-skeleton{
  /* height: 219px; */
  position: relative;
  overflow: hidden;
  display: inline-block;
  transition: 0.2s;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
}
.card05-skeleton::before{
  content: "";
  width: 100%;
  display: block;
  background-color: #212121;
  border-radius: 6px 6px 0px 0px;
  aspect-ratio: 1 / 1;
}
.vertical-container .card05-skeleton{
  width: 200px;
  margin-right: 10px;
}
.card05-skeleton .details{
  width: 100%;
  padding: 15px;
  background: #1c1c1c;
}
.card05-skeleton .details p:first-child{
  margin-top: 0px;
}
.card05-skeleton .details p{
  height: 12px;
  width: 80%;
  background: #282828;
  border-radius: 50px;
  margin:0px;
  margin-top: 10px;
}
.card05-skeleton .details p:last-child{
  width: 50%;
}

/* albumcard skeleton */
.albumcard-skeleton{
  width: 100%;
  overflow: hidden;
  border-radius: 6px;
  display: inline-block;
  background-color: #212121;
  margin-right: 10px;
  margin-bottom: 20px;

}
.vertical-container .albumcard-skeleton{
  width: 200px;
  margin-bottom: 0px;
}
.albumcard-skeleton .tp{
  background-color: #282828;
  aspect-ratio: 1 / 1;
}
.albumcard-skeleton .bt{
  padding: 15px;
}
.albumcard-skeleton .bt p{
  width: 80%;
  height: 10px;
  background-color: #282828;
  border-radius: 50px;
  margin: 0px;
  margin-top: 3px;
}
 .albumcard-skeleton .bt p:last-child{
  width: 60%;
  margin-top: 10px;
}

/* card13 */
.card13-skeleton{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  border-bottom: 1px solid #2c2c2c;
}
.card13-skeleton p{
  background-color: #212121;
  margin-bottom: 0px;
}
.card13-skeleton .lft{
  width: 50%;
}
.card13-skeleton .lft p{
  height: 10px;
  width: 100%;
  margin: 0px;
  border-radius: 50px;
}


.card13-skeleton .rgt p{
  border-radius: 3px;
  height: 34px;
  min-width: 64px;
  margin: 0px;
  margin-left: 6px;
}


.search-skeleton{
  padding: 16px 10px;
}
.search-skeleton p{
  height: 10px;
  background-color: #212121;
  border-radius: 20px;
  margin-bottom: 15px;
}
.search-skeleton p:nth-child(1){
width: 50%;
}
.search-skeleton p:nth-child(2){
  width: 70%;
}
.search-skeleton p:nth-child(3){
  width: 40%;
}
.search-skeleton p:nth-child(4){
  width: 60%;
}
.search-skeleton p:nth-child(5){
  width: 30%;
}
.search-skeleton p:nth-child(6){
  width: 40%;
}
.search-skeleton p:nth-child(7){
  width: 50%;
}
.search-skeleton p:nth-child(8){
  width: 70%;
}
.search-skeleton p:nth-child(9){
  width: 50%;
}
.search-skeleton p:nth-child(10){
  width: 60%;
}
.search-skeleton p:nth-child(11){
  width: 70%;
}
.userbanner-skeleton{
  margin-bottom: 10px;
}
.userbanner-skeleton .pt1{
  padding: 30px 15px 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.userbanner-skeleton .pt1 .lft{
  width: 100%;
}
.userbanner-skeleton .pt1 .lft p{
  background-color: #212121;
  width: 70%;
  border-radius: 50px;
  height: 13px;
}
.userbanner-skeleton .pt1 .lft p:last-child{
  width: 40%;
  margin-bottom: 0px;
}

.userbanner-skeleton .pt1 .rgt div{
  background-color: #212121;
  width: 90px;
  min-width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-left: 20px;
}
.userbanner-skeleton .pt2{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 17px auto 20px auto;
  max-width: 600px;
}
.userbanner-skeleton .pt2 div{
  width: 50%;
  background-color: #212121;
  height: 43px;
  margin-right: 5px;
}
 .userbanner-skeleton .pt2 div:nth-child(1){
border-radius: 50px;
}
.userbanner-skeleton .pt2 div:nth-child(2){
  border-radius: 50%;
  min-width: 43px;
  height: 43px;
  width: 43px;
  margin-right: 0px;
}
.userbanner-skeleton .pt3 div{
  border-top: 1px solid #282828;
  border-bottom: 1px solid #282828;
  width: 100%;
  height: 62px;
}


.card005-skeleton{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #282828;
  padding: 10px 0px;
  height: 90px;
}
.card005-skeleton .lft{
  width: 80%;
}
.card005-skeleton .lft p{
  background-color: #353535;
  width: 70%;
  height: 11px;
  margin: 0px;
  margin-bottom: 10px;
  border-radius: 50px;
}
.card005-skeleton .lft div{
  display: flex;
}
.card005-skeleton .lft div p{
  width: 26px;
  margin-right: 10px;
  margin-bottom: 0px;
  height: 20px;
  border-radius: 3px;
}
.card005-skeleton .rgt div{
  background-color: #353535;
  width: 70px;
  height: 70px;
  min-width: 70px;
  border-radius: 6px;
}

/* Userbanner02 */
.userbanner02{
  height: 90px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #212121;
  margin-bottom: 0px;
}
.userbanner02.active{
  height: 0px;
}

.userbanner02 .background{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0px;
  opacity: 0.7;
  filter: saturate(2);
  -webkit-filter: saturate(2);
  -moz-filter: saturate(2);
  -o-filter: saturate(2);
  -ms-filter: saturate(2);
  transition: 0.6s;
  border-top: 1px solid rgb(255 255 255 / 10%);
}
.userbanner02 .user{
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  width: 100%;
}
.userbanner02 .user .wrapper{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 0px;
}
.userbanner02 .user .lf{
  display: flex;
  align-items: center;
}
.userbanner02 .user .lf:hover{
  opacity: 0.8;
}
.userbanner02 .user .lf .ppimg{
  margin-right: 10px;
  width: 60px;
  height: 60px;
  display: block;
  background-color: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: #fff;
  font-size: 17px;
  object-fit: cover;
  object-position: center;
  text-transform: uppercase;
}


.userbanner02 .user .lf .details p{ 
  color: #fff;
  display: block;
  margin: 0px;
  text-shadow: 0px 1px 3px rgb(0 0 0 / 50%);
}
.userbanner02 .user .lf .details p:last-child{
  opacity: 0.7;
  text-transform: capitalize;
}
.userbanner02 .user .lf .details p:first-child{
    font-weight: 600;
}
.userbanner02 .user .rt .details p{ 
  display: flex;
  margin: 0px;
  padding: 5px 8px;
  margin-bottom: 5px;
  border-radius: 3px;
  font-weight: 400;
  background-color: rgb(0 0 0 / 15%);
  color: #fff;
  opacity: 0.7;
  justify-content: space-between;
  min-width: 93px;
  align-items: center;

}
.userbanner02 .user .rt .details p span{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 28px;
}
.userbanner02 .user .rt .details p span::before{
  position: absolute;
  content: "";
  height: 70%;
  width: 1px;
  left: 0px;
  background-color: rgb(255 255 255 / 29%);
}
.userbanner02 .user .rt .details p:nth-child(2){ 
  margin-bottom: 0px;

}

.passwordfield{
  display: flex;
  align-items: center; 
  position: relative;
  margin-bottom: 10px;
}
.passwordfield .MuiFormControl-root{
width: 100%;
margin-bottom: 0px;
}
.passwordfield .icon{
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  color: #DF1233;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  transition: 0.1s;
  cursor: pointer;
}
.passwordfield .icon:active{
  opacity: 0.7
}
.landingpage .myform{
padding: 0px;
margin-top: 20px;
}
.myform .mylabel{
  color: #959595;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s;
  margin: 0px;
  min-height: 26px;
  margin-bottom: 10px;
}

.myform .mylabel .icon{
  display: flex;
  align-items: center;
}
.myform .mylabel.error .icon svg,
.myform .mylabel.error{
  color: #DF1233;
  fill: #DF1233;
}
.myform .mylabel .icon svg{
  margin-left: 5px;
}
.mycontainer{
  padding-top: 56px;
  overflow: hidden;
  padding-bottom: 100px;
}
.mycontainer.uploadcomplete{
  padding-top: 56px;
}
.card001{
  width: 100%;
  display: inline-block;
  overflow: hidden;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
}
.vertical-container .card001{
  width: 200px;
  margin-bottom: 0px;
  margin-right: 10px;
}
.vertical-container .card001 .placeholder{
  height: 200px;
  width: 200px;
}
.card001 .placeholder{
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1 / 1;
}
.card001 .placeholder .type{
  background-color: rgb(33 33 33);
  position: relative;
  z-index: 1;
  margin: 10px;
  display: inline-flex;
  padding: 6px 15px;
  max-width: 82%;
  border-radius: 6px
}
.card001 .placeholder .type p{
  margin: 0px;
  display: inline-block;
  color: #fff;
  font-weight: 500;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  font-size: 11px;
 
}
.card001 .placeholder .type p a{
  color: inherit;
  text-decoration: unset;
  font-size: inherit;
  text-transform: capitalize;
}

.card001 .placeholder img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  top: 0px;
  left: 0px;
  position: absolute;
}
.card001 .placeholder .playbtn{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  transition: 0.2s;
}
.card001 .placeholder .playbtn.active{
  opacity: 1!important;
}
.card001 .placeholder:hover .playbtn{
opacity: 0.7;
background-color: rgb(0 0 0 / 50%);
}
.card001 .placeholder .playbtn button{
    color: #fff;
    background-color: #df1233;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    min-width: 30px;
    position: absolute;
    left: 0px;
    bottom: 0px;
    margin: 15px;
}
.card001 .placeholder .playbtn button svg {
    width: 25px;
    height: 25px;
}


.card001 .details{
  padding: 13px 15px 8px 15px;
  background-color: #212121;
}

.card001 .details .titles p{
  margin: 0px;
  white-space: nowrap;
}
.card001 .details .titles p:first-child{
  margin-bottom: 3px;
  color: #fff;
  font-weight: 600;

}
.card001 .details .titles p time{
  font-size: 11px;
  font-style: italic;
}
.card001 .details .titles p:first-child::after{
  display: block;
  content: "";
}
.card001 .details .titles p:first-child a{
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-decoration: unset;
  transition: 0.2s;
  width: 100%;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.card001 .details .titles p:last-child{
  font-size: 12px;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card001 .details .titles p a{
    color: inherit;
    margin-right: 5px;
    /* font-weight: 600; */
    text-decoration: unset;
}
.card001 .details .titles p a:hover{
  text-decoration: underline;
}
.card001 .details .stats{
  display: flex;
  align-items: center;
}
.card001 .details .stats .myicon{
  display: flex;
  align-items: center;
  min-width: 10px;
  height: 26px;
  padding: 0px;
  padding-right: 10px;
}
.card001 .details .stats .myicon {
  justify-content: flex-start;
}
.card001 .details .stats  .myicon .MuiCircularProgress-root{
  width: 17px!important;
  height: 17px!important;
}
.card001 .details .stats .myicon .MuiCircularProgress-root svg{
  margin: 0px;
}

.card001 .details .stats .myicon svg{
  font-size: 15px;
  color: #fff;
  fill: #fff;
  margin-right: 3px;
  opacity: 0.3;
}
.card001 .details .stats .myicon.active svg{
  color: #DF1233;
  fill: #DF1233;
  opacity: 1;
}
.card001 .details .stats .myicon p{
    margin: 0px;
    font-size: 11px;
    line-height: 11px;
    text-align: left;
    white-space: nowrap;
    color: #7d7d7d;
}
section{
  margin-bottom: 20px;
}
.swiper-pagination-bullet-active{
  background-color: #DF1233!important;
}

.mySwiper .swiper-button-prev, .mySwiper .swiper-button-next{
  height: 50px;
  background-color: #282828;
  width: 30px !important;
  border-radius: 0px;
  opacity: 0;
  transition: 0.3s;
  top: 47%;
  bottom: 50%;
}

.mySwiper .swiper-button-next {
  right: 0px!important;
}
.mySwiper .swiper-button-prev {
  left: 0px!important;
}
.mySwiper .swiper-button-prev:after, 
.mySwiper .swiper-button-next:after {
  color: #fff;
  font-size: 15px;
}
.mySwiper:hover .swiper-button-prev, 
.mySwiper:hover .swiper-button-next{
  opacity: 1;
}
.mySwiper .swiper-button-next.swiper-button-disabled, .mySwiper .swiper-button-prev.swiper-button-disabled{
  opacity: 0;
}
.card004{
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
  background-color: #212121;
  border-radius: 6px;
  margin-top: 10px;
  border-radius: 6px;
  min-height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 0px;
}

.card004{

}
.card004 .icon{
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 18px;
  text-decoration: unset;
  opacity: 0.5;
}
.card004 .icon svg{
  fill: #fff;
  width: 30px;
  height: 30px;
}
.card004 .icon svg path{
width: inherit;
height: inherit;
}
.card004 .contain{

}
.card004 .contain .caption{
  
}
.card004 .contain .caption p{
  color: rgb(255 255 255 / 50%);
  margin: 0px;
}
.card004 .contain .caption p:first-child{
  color: #fff;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(255 255 255 / 19%);
  display: inline-block;
  margin-bottom: 15px;
  font-weight: 600;
}
.card004 .contain .caption p a{
  text-decoration: underline;
  font-weight: 500;
  color: inherit;
  display: inline-flex;
  margin-left: 3px;
  white-space: nowrap;
  margin-bottom: 10px;
}
.card004 .contain .caption p a:hover{
  opacity: 0.7;
}
.card004 .background{
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  opacity: 1;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: inherit;
  border-radius: 6px;
  opacity: 0.7;
  filter: saturate(2);
  -webkit-filter: saturate(2);
  -moz-filter: saturate(2);
  -o-filter: saturate(2);
  -ms-filter: saturate(2);
  transition: 0.6s;
}


.card004 .contain{
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  padding: 20px 30px;
}
.card004 .contain .kpi{
  text-align: center;
  width: 100%;
  margin: 10px;
}
.card004 .contain .kpi p{
    margin: 0px;
    color: #fff;
}
.card004 .contain .kpi p:first-child{
  font-weight: 600;
  font-size: 15px;
}
.card004 .contain .kpi p:last-child{
  border-top: 1px solid rgb(255 255 255 / 10%);
  padding-top: 10px;
  margin-top: 10px;
  color: rgb(255 255 255 / 70%);
}
.mypath{
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  padding: 10px 0px;
  background-color: #181818;
  position: relative;
  z-index: 1;
}
.mypath .wrapper{
  display: flex;
  align-items: center;
}

.mypath .wrapper button{
  margin-right: 15px;
  color: #ccc;
  background-color: #212121;
  
}
.mypath .wrapper button svg{
  color: #fff;
}
.mypath .wrapper button.Mui-disabled{
  opacity: 0.4;
}
.mypath .wrapper .rgt{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.mypath .wrapper .rgt button{
  margin-right: 0px;
  width: 35px;
  height: 35px;
  min-width: 35px;
}
.mypath .wrapper .rgt p{
  margin: 0px;
}
.mypath .wrapper .rgt p a{
  color: rgb(255 255 255 / 50%);
  margin-right: 10px;
}
.mypath .wrapper .rgt p a.active{
  font-weight: 600;
  color: #fff;
}
.no-padding-top{
  padding-top: 0px!important;
}
.imgcrop .ant-upload.ant-upload-select{
  border: 1px dashed #3e3e3e!important;
  font-size: 12px!important;
  color: #df1233!important;
  font-weight: 600;
  transition: 0.3s!important;
}
.imgcrop .ant-upload.ant-upload-select:hover{
  background-color: #101010;
}
.profilebanner .userpp,
.imgcrop .ant-upload-list-item-container,
.imgcrop .ant-upload.ant-upload-select,
.imgcrop{
  font-size: 13px!important;
  width: 200px!important;
  height: 200px!important;
}

.ant-slider .ant-slider-handle::after{
  box-shadow: 0 0 0 2px #df1233!important;
}
.ant-slider .ant-slider-handle:active::after,
.ant-slider .ant-slider-handle:focus::after,
.ant-slider .ant-slider-handle:hover::after{
  box-shadow: 0 0 0 4px #df1233!important;
}
.ant-modal .ant-slider-handle {
  z-index: 1;
}
.imgcrop .ant-upload-list-item.ant-upload-list-item-error{
  border-color: #2c2c2c!important;
}
.imgcrop .ant-upload-list-item-actions a{
  display: none;
}
.imgcrop .ant-upload-list-item-action{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 35px!important;
  width: 35px!important;
  
}
.imgcrop .ant-upload-list-item-actions button svg{
  font-size: 30px!important;
}
.ant-modal-mask{
  background-color: #181818!important;
}

.ant-modal .ant-modal-footer button{
  font-size: 13px!important;
}
.card006{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0px;
  border-bottom: 1px solid #323232;
}
.card006 .lft{
  
}
.card006 .rgt{
  
}
.card006 .lft p{
  margin: 0px;
  font-weight: 400;
}
.card006 .lft p:first-child{
    color: #fff;
    font-weight: 600;
} 
.card006 .rgt svg{
  color: #8d8d8d; 
}
.card006 .rgt svg.error{
  fill: #DF1233;
}
.FilterPanel{
  margin-bottom: 5px;
  margin-top: 10px;
}
.FilterPanel .searchbar{
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 15px;

  border-radius: 10px 10px 0px 0px;
}
.FilterPanel .searchbar svg{
  fill: #DF1233;
  color: #DF1233;
  position: absolute;
  left: 0px;
  font-size: 27px;
}
.FilterPanel .searchbar input{
  border: 0px;
  background-color: transparent;
  padding-left: 40px;
  border-bottom: 1px solid #282828;
  width: 100%;
  height: 47px;
  color: #fff;
  border-radius: 0px;
}
.FilterPanel .searchbar input:active,
.FilterPanel .searchbar input:focus{
  border-bottom: 2px solid #df1233;
}


.FilterPanel .filter{
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
} 
.FilterPanel .filter .lft{
  display: flex;
  width: 100%;
  overflow-x: scroll;
  position: relative;
  padding-right: 130px;
} 

.FilterPanel .filter button{
  color: #fff;
  border: 1px solid #282828;
} 
.FilterPanel .filter .lft button{
  margin-right: 15px;
  border-radius: 50px;
  padding: 0px 15px;
  height: 40px;
  white-space: nowrap;
  overflow: hidden;
  
}
.FilterPanel .filter .lft button .icon{
display: flex;
justify-content: center;
align-items: center;
margin-left: 15px;
}
.FilterPanel .filter .lft button .icon img{
  height: 13px;
  width: 13px;
}
.FilterPanel .filter .separator{
  height: 30px;
  width: 1px;
  background-color: #282828;
  display: block;
  min-width: 1px;
  margin: auto 0px;
  margin-right: 15px;
}
.FilterPanel .filter .rgt{
  display: flex;
  align-items: center;
}

.FilterPanel .filter .rgt button{
  height: 40px;
  width: 40px;
  min-width: 40px;
  line-height: 0px;
  border-radius: 50px!important;
} 
.FilterPanel .filter .lft button.active{
  background-color: #DF1233!important;
  border: 1px solid #DF1233!important;
}

.FilterPanel .filter .lft button p{
  color: #fff!important;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: 0px;
  line-height: 0px;
}
.FilterPanel .filter .lft button span{
  font-size: 9px;
  margin-left: 6px;
  font-style: italic;
  opacity: 0.5;
  line-height: 0px;
}
.card007-skeleton{
  width: 100%;
  margin-top: 0px;
  padding: 20px;
  height: 190px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid #282828;
}
.card007-skeleton .lft{
  display: flex;
  align-items: center;
}
.card007-skeleton .lft .titles{
  width: 60%;
}
.card007-skeleton .circle {
  width: 70px;
  min-width: 70px;
  background-color: #353535;
  height: 70px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 15px;
}
.card007-skeleton .lft p:first-child{
  width: 100%;
  margin-bottom: 5px;
}
.card007-skeleton .lft p{
  width: 100%;
  background-color: #353535;
  height: 11px;
  border-radius: 50px;
  margin: 0px;
  display: inline-block;
  width: 40%;
}
.card007-skeleton .rgt{
  
}

.card007{
  margin-top: 0px;
  padding: 20px;
  height: 190px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  border: 1px solid #282828;
}

.card007 .info{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: relative;
  z-index: 2;
}
.card007 .info .lft{
  display: flex;
  align-items: center;
}
.card007 .info .lft .thumbnail{
  width: 70px;
  min-width: 70px;
  background-color: #212121;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  position: relative;
  overflow: hidden;
}
.card007 .info .lft .thumbnail p{
  margin: 0px;
  transform: scale(1.6);
  color: #fff;
  text-transform: uppercase;
}
.card007 .info .lft .thumbnail img{
  left: 0px;
  top: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;

}
.card007 .info .lft .details{

}
.card007 .info .lft .details p{
  margin: 0px;
}
.card007 .info .lft .details p a{
  color: inherit;
}
.card007 .info .lft .details p:first-child{
  font-weight: 600;
  color: #fff;
  margin-bottom: 3px;

}
.card007 .info .rgt{

}
 .card007 .info .rgt button{
  border-radius: 50px;
  min-width: 117px;
} 
.card007 .stats{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgb(255 255 255 / 8%);
  padding-top: 10px;
  position: relative;
  z-index: 3;
}
.card007 .stats .column{

}
.card007 .stats .column:nth-child(2){
  text-align: center;
}
.card007 .stats .column:nth-child(3){
  text-align: right;
}
.card007 .stats .column p{
margin: 0px;
}
.card007 .stats .column p:first-child{
  font-weight: 600;
  color: #fff;
  margin-bottom: 5px;
  font-size: 14px;
}
.myshadow{
  box-shadow: 3px 3px 30px 1px rgb(0 0 0 / 10%);
}
.rightmenu {
  padding-left: 0px;
  border-top: 1px solid #282828;
  padding-top: 30px;
  margin-top: 40px;
}
.currentview{
  animation-name: fx123;
  animation-duration: 0.6s;
  animation-iteration-count: initial;
  transition: .6s;
  overflow: hidden;
}
@keyframes fx123{
  /* 0%{height: 0px}
  100%{height: 37px} */
}

.currentview button.active > p{
  font-weight: 600!important;
  color: #fff!important;
  opacity: 1;
}
.currentview .imgicon{
  width: 30px;
  min-width: 30px;
  height: 30px;
  background-color: rgb(0 0 0 / 37%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.currentview .imgicon.song{
  border-radius: 6px;
}
.currentview .imgicon img{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sidebar .contain .menu .tabs .currentview .imgicon p,
.navigationmenu .currentview .imgicon p{
  font-weight: 400!important;
  color: #fff!important;
  text-align: center;
  text-transform: uppercase;
}
.FilterPanel .keywords{
  display: flex;
  overflow-x: scroll;
  width: 100%;
  max-width: 100%;
}
.FilterPanel .keywords button{
  height: 34px;
  padding: 5px 7px 5px 14px;
  margin-right: 15px;
  border-radius: 50px;
  border: 1px solid #292929;
  margin-bottom: 0px;
  margin-top: 15px;
  width: unset;
  min-width: unset;
}
.FilterPanel .keywords button p{
  margin: 0px;
  margin-right: 10px;
  font-size: 11px;
  white-space: nowrap;
}
.FilterPanel .keywords button svg{
  color: #DF1233;
  fill: #DF1233;
}


.navbar{
  z-index: 999!important;
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: #212121;
  padding-top: env(safe-area-inset-top);
}
.navbar::after{
  background-color:var(--userbannerbg);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  opacity: 0.7;
  content: "";
  filter: saturate(2);
  -webkit-filter: saturate(2);
  -moz-filter: saturate(2);
  -o-filter: saturate(2);
  -ms-filter: saturate(2);
  transition: 0.6s;
}
.navbar .wrapper{
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}
.navbar .wrapper.transparent{
  background-color: transparent;
}


.navbar .lft .brandlogo{
  height: 34px;
  width: 130px;
}
.navbar .lft {
  align-items: center;
  white-space: nowrap;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.navbar .lft button{
  color: #fff;
}
.navbar .lft button svg{
  color: #fff;
  fill: #fff;
}
.navbar .wrapper .lft .titles p{
  display: block;
  font-weight: 600;
  font-size: 12px;
  color: #fff;
  margin: 0px;
  text-shadow: 0px 1px 3px rgb(0 0 0 / 30%);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
 .navbar .wrapper .lft .titles p:nth-child(2){
  font-weight: 400;
  opacity: 0.8;
}
.navbar .wrapper .rgt{
 display: flex;
 align-items: center;
}
.navbar .wrapper .rgt .useraccount{
  padding: 0px;
  display: flex;
  padding-right: 30px;
  align-items: center;
  border-radius: 50px;
  justify-content: flex-start;
  min-height: 36px;
  color: #fff;
}

.navbar .wrapper .rgt .useraccount .usericon{
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-right: 0px;
  position: relative;
  border: 1px solid rgb(255 255 255 / 10%);
}

.navbar .wrapper .rgt .useraccount{
  padding: 0px!important;
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .wrapper .rgt .useraccount .usericon img{
  position: absolute;
  width: 100%;
  height: 100%;
  min-width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 2px solid rgb(255 255 255 / 30%);
}
.navbar .wrapper .rgt .useraccount.loggedin{
  color: #ccc;
  padding-left: 6px;
  /* background-color: #181818; */
}
.navbar .wrapper .rgt .useraccount.loggedin:hover{
  background-color: rgb(0 0 0 / 10%);
}
.navbar .wrapper .rgt .useraccount.notloggedin{
  background-color: #ffff;
  display: block;
  width: 76px;
}

.navbar .wrapper .rgt .useraccount.notloggedin{
  padding-right: 0px;

}
.navbar .wrapper .rgt .useraccount .usericon p{
  margin: 0px;
  text-transform: uppercase;
  color: #fff;
  position: relative;
  z-index: 1;
}
.navbar .wrapper .rgt .useraccount .details{
  width: 100%;
}
.navbar .wrapper .rgt .useraccount.loggedin .details{

display: none;
}
.navbar .wrapper .rgt .useraccount .details p{
  margin: 0px;
  text-transform: none;
  font-weight: 500;
  text-align: left;
  color: #fff;
  white-space: nowrap;
}

.navbar .wrapper .rgt .useraccount.notloggedin p{
  font-weight: 600;
  text-align: center;
  color: #181818;
}
.navbar .wrapper .icon{
  width: 45px;
  height: 45px;
  min-width: 45px;
  color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar .wrapper .icon svg{
    fill: #fff;
    font-size: 27px;
}
/* navBar */

.navbar .MuiBadge-badge {
  font-size: 11px;
  top: 10px;
  right: 10px;
}
.navbar .MuiBadge-badge, .navigationmenu .MuiBadge-badge{
  font-size: 11px;
}
.navbar .searchinput{

width: 100%;
}
.navbar .searchinput input{
  outline: none;
  height: 40px;
  width: 100%;
  font-size: 13px;
  border: 0;
  padding: 10px 0px 7px 0px;
  border-bottom: 2px solid transparent;
  transition: 0.5s;
  border-radius: 0px;
  font-weight: 600;
}
.navbar .searchinput input:focus{
  padding: 10px 10px 7px 10px;
  border-radius: 6px;
}

.navbar .brandlogo:active{
  opacity: 0.7;
}

.navigationmenu {
  width: 67%;
  max-width: 300px;
  z-index: 9999999;
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  height: 100vh;
  height: 100dvh;
}


.navigationmenu .contents{
  max-width: 1000px;
  margin: 0 auto;
  background: #212121;
  padding: 30px;
  width: 100%;
  padding-bottom: 50px;
  overflow: hidden;
  position: absolute;
  height: 100%;
  overflow-y: scroll;
  overflow-y: scroll;
  box-shadow: -1px 0px 7px 0px rgb(0 0 0 / 30%);
}
.navigationmenu .contents .background{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  animation-name: fx123;
  animation-duration: 1s;
  animation-iteration-count: initial;
  transition: 1s;
}

.navigationmenu .contents .background img{
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
  transform: scale(1.3);
  opacity: 0.4;
  filter: blur(20px) saturate(1.5);
  -webkit-filter: blur(20px) saturate(1.5);
  -moz-filter: blur(20px) saturate(1.5);
  -o-filter: blur(20px) saturate(1.5);
  -ms-filter: blur(20px) saturate(1.5);
}
.navigationmenu .contents .profile{
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0px;
  padding-top: env(safe-area-inset-top);
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  position: relative;
  z-index: 999;
  padding-bottom: 15px;
}
.navigationmenu .contents .profile .userpic{
  background-color: rgb(0 0 0 / 50%);
  margin: auto;
  color: #fff;
  width: 90px;
  height: 90px;
  min-width: 90px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 25px;
  margin-bottom: 15px;
  font-weight: 400;
  transition: 0.2s;
  text-decoration: none;

}
.navigationmenu .contents .profile .userpic:active{
  opacity: 0.7;
}
.navigationmenu .contents .profile .titles:active{
opacity: 0.7;
}
.navigationmenu .contents .profile .titles p{
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  display: block;
  margin: 0px;
}
.navigationmenu .contents .profile .titles p:nth-child(2){
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
  opacity: 0.7;
}
.navigationmenu .contents{
  align-items: center;
  justify-content: center;
}
.navigationmenu .contents button{
  width: 100%;
  padding: 0px;
  height: 43px;
  display: block;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgb(255 255 255 / 10%);
  border-radius: 0;
  color: #aaa;
  background-color: transparent;
  animation-name: navbtnsfx;
  animation-duration: 0.4s;
  animation-iteration-count: initial;
  transition: 0.4s;
  transform: translateY(0%);
  margin-bottom: 0px;
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigationmenu .contents button .mybadge{
  min-width: 20px;
  width: 20px;
  background-color: #DF1233;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}
.navigationmenu .contents button .mybadge p{
  margin: 0px;
  color: #fff;
  
}
@keyframes navbtnsfx{
  0%{transform: translateY(50%);}
  100%{transform: translateY(0%);}
}



.navigationmenu .contents button .title{
  color: #fff;
  text-transform: none;
  font-size: 13px;
  line-height: 15px;
  text-align: left;
  margin: 0px;
  font-weight: 400;
  opacity: 0.7;
  white-space: nowrap;
  width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.navigationmenu .contents button.active .title{
  color: #fff!important;
  font-weight: 600;
  opacity: 1;
}

.completeprofile{
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0px 10px; */
  margin-bottom: 10px;
}
.completeprofile .card04{
  text-align: center;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  padding: 15px;
  width: 100%;
  opacity: 1;
  transition: 0.2s;
  border: 1px solid #282828;
  
}
.completeprofile .card04:first-child{
  border-left: 0px;
}
.completeprofile .card04:last-child{
  border-right: 0px;
}
.completeprofile .card04 p{
display: flex;
align-items: center;

}
.completeprofile .card04 p svg{
  margin-left: 10px;  
}

.completeprofile .card04:last-child{
  border-left: 0px;
}
.completeprofile .card04:hover{
  background-color: #0f0f0f;
}
.completeprofile .card04 .icon svg{
  font-size: 27px;
  color: #DF1233;;

}
.completeprofile .card04 p{
color: #aaa;
}
.recoverpassword{
  padding-top: 10px;
}
.recoverpassword .htitle{
  
}
.recoverpassword .htitle p{
  
}
.recoverpassword .otppar{
  display: block;
  
}
.recoverpassword .otppar a{
  color: #DF1233;
  text-decoration: unset;
  white-space: break-spaces;
  margin-top: 3px;
  display: inline-block;
}
.recoverpassword .email-sent{
text-align: center;
}
.recoverpassword .email-sent svg{
  font-size: 65px;
  fill: #df1233;
  display: block;
  margin: 0 auto;
}
.recoverpassword .email-sent p{

}
.recoverpassword .email-sent p a{
  color: #DF1233;
  font-weight: 500;
}
.recoverpassword i{
  color: #959595;
}
.MuiModal-root{
  display: flex;
  align-items: center;
  z-index: 999999!important;
}
.MuiModal-root .viewImg .img{
  padding: 0px!important;
  position: relative;
  z-index: 3;
  border-radius: 6px;
  margin-top: -10vh;
  height: 310px;
  width: 310px;
  min-width: 310px;
  object-fit: cover;
  object-position: center;
}
.MuiModal-root .viewImg .img.profile{
  border-radius: 50%;
}
.banner01{
  /* padding-bottom: 20px; */
}
.banner01 .card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: calc(100% - 1px);
  min-height: 150px;
  border: 1px solid #282828;
  border-radius: 6px;
  padding: 20px;
  overflow: hidden;
}
.banner01 .card .lft{
  z-index: 1;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.banner01 .card .lft p{
  color: #fff;
  white-space: normal;
  margin: 0px;
  font-size: 15px;

}
.banner01 .card .lft p:first-child{
    font-weight: 400;
}

.banner01 .card .lft p a{
  color: #fff;
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 10px;
  font-weight: 400;
}
.banner01 .card .lft p a:hover{
  text-decoration: underline;
}
.banner01 .card .lft p:last-child{
  border-top: 1px solid rgb(255 255 255 / 15%);
  padding-top: 5px;
  margin-top: 10px;
  font-size: 12px;
  display: inline-block;
}
.banner01 .card .lft p:last-child a{
  display: inline;
  color: #DF1233;
  font-weight: 600;
}
.banner01 .card .lft p:last-child a{
  font-size: inherit;
}
.banner01 .card .lft p:last-child a:hover{
  text-decoration: underline;
}
.banner01 .card .lft p time{
  color: #A5A1A1;
  display: inline-block;
  font-size: 11px;
  font-style: italic;
  white-space: nowrap;
}
.banner01 .card .artwork{
  margin-left: 20px;
  margin-right: 10px;
  height: 60px;
  width: 60px;
  min-width: 60px;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  text-decoration: unset;
  transition: 0.3s;
}
.banner01 .card .artwork:hover{
  transform: scale(1.07);
}
.banner01 .card .artwork svg{
  width: 100%;
  height: 100%;
}
.banner01 .card .artwork img{
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    left: 0px;
    top: 0px;
}
.banner01 .card .background{
  position: absolute;
}
.banner01-skeleton{
margin-bottom: 40px;
display: flex;
align-items: center;
justify-content: space-between;
border: 1px solid #282828;
border-radius: 6px;
min-height: 150px;
padding: 20px;
}
.banner01-skeleton .lft{
  width: 70%;
}
.banner01-skeleton .lft p{
margin: 0px;
margin-bottom: 10px;
background-color: #181818;
height: 11px;
border-radius: 50px;
}
.banner01-skeleton .lft p:nth-child(2){
width: 60%;
}
.banner01-skeleton .lft p:nth-child(3){
  width: 80%;
}
.banner01-skeleton .rgt{
  margin-left: 10px;
  height: 100px;
  width: 100px;
  min-width: 100px;
  border-radius: 6px;
  background-color: #181818;
}
.home-banner{
  position: relative;
  width: 100%;
  /* min-height: calc(100vh - 56px);
  min-height: calc(100dvh - 56px);; */
  min-height: 270px;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #000;
  margin-bottom: 5px;
  transition: 0.6s;
}

.home-banner .swiper{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}
.home-banner .background{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 3;
  opacity: 0.5;
  background-color: #000;
}
.home-banner img{
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  left: 0px;
  top: 0px;
  z-index: 2;
  background-color: #000;
}
.home-banner img.blurFx{
  filter: blur(10px) grayscale(1);
  transform: scale(1.2);
}
.home-banner .wrapper{
  z-index: 5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.home-banner .wrapper .lft{

  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin: auto;
}
.home-banner .wrapper .lft b{
font-weight: 600;
}
.home-banner .wrapper .lft p{
margin: 0px;
color: #fff;
font-weight: 400;
font-size: 18px;
text-align: center;
padding:10px;
max-width: 350px;
margin-top: -4vh;
}
.home-banner .wrapper .rgt{
  display: block;
  position: absolute;
  bottom: 0px;
  align-items: center;
  text-align: center;
  width: 100%;
  left: 0px;
  padding: 10px 10px;
}
.home-banner .wrapper .rgt button{
  color: rgb(255 255 255 / 70%);
  position: relative;
  display: inline-block;
  margin: 0 auto;
  text-transform: none;
  line-height: 18px;
  border: 0px;
  text-transform: uppercase;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 10px!important;
}
.home-banner .wrapper .rgt button span{
  font-weight: 600;
  display: block;
  margin-right: 0px;
  color: #fff;
  font-size: 15px;
}
.home-banner .wrapper .rgt button:first-child{
  margin-left: 0px;
}
.home-banner .wrapper .rgt button:last-child{
  margin-right: 0px;
}
.home-banner .wrapper .rgt a{
  display: inline-block;
  margin: 0 auto;
}

.home-banner .wrapper .rgt button:hover{
  background-color: rgb(255 255 255 / 20%);
}

.home-banner .wrapper .rgt .btns{
  display: flex;
  align-items: center;
  max-width: 350px;
  margin: 0 auto;
  justify-content: space-between;
}
.home-banner .wrapper .rgt .separator{
  height: 20px;
  width: 1px;
  background-color: #fff;
  margin: 0px 3px;
  opacity: 0.4;
}
.about-us{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
.about-us .contain{
  display: flex;
  flex-direction: column-reverse;
}
.about-us .contain .lft{
  margin-right: 0px;
  padding-right: 0px;
  text-align: center;
  max-width: 80%;
  margin: 0px auto;
  margin-top: 25px;
}
.about-us .contain .lft p{
  margin: 0px;
}
.about-us .contain .logo img{
  width: 175px;
  margin: 0 auto;
  display: block;
}
.about-us .contain .lft p a{
  color: #DF1233;
}
.about-us .contain .lft p a:hover{
  text-decoration: underline;
}
/* Media Queries */
@media (min-width:992px) {
  .CommentCard time{
    font-size: 10px;
  }
  .FilterPanel .keywords button{
    margin-top: 10px;
  }
  .FilterPanel .filter .rgt button {
    border-radius: 6px !important;
  }
  .htitle p a{
    font-size: 12px;
  }

  .htitle{
    height: 45px;
  }
  .htitle .lft p .platform{
    font-size: 10px;
  }
  .htitle p{
    font-size: 14px;
  }
  .navbar .wrapper .rgt .useraccount .usericon p{
    margin-right: -10px;
  }
  .home-banner img.blurFx{
    filter: blur(12px) grayscale(1);
    transform: scale(1.4);
  }
  .home-banner .wrapper .rgt button {
      font-size: 13px !important;
  }

  .home-banner .wrapper .rgt button span{
    margin-right: 5px;
  }
  .home-banner .wrapper .rgt button{
    display: flex;
    align-items: center;
  }
  .banner01 .card .lft p {
      font-size: 17px;
  }
  .about-us .contain{
    align-items: center;
    flex-direction: row;
    max-width: 50%;
  }
  .about-us .contain .lft{
    border-right: 1px solid rgb(255 255 255 / 10%);
    margin-right: 25px;
    padding-right: 25px;
    margin-bottom: 0px;
    margin-top: 0px;
    text-align: left;
  }
  .about-us{
    height: 68vh;
  }
  .about-us .contain .logo img{
    width: 175px;
  }
  .uploading-container .songart{
    height: 300px;
    width: 300px;
  }
  .userbanner .lft .titles02::before{
    margin-bottom: 15px;
  }
  .home-banner .wrapper .rgt .separator{
    height: 15px;
    margin: 0px 5px;
  }
  .home-banner .wrapper .rgt .btns{
    padding:0px;
    max-width: unset;
  }
  .home-banner .wrapper .lft p{
    font-size: 15px;
    margin-top: 0px; 
    text-align: left;
    padding: 10px 0px;
    max-width: unset;
  }
  .home-banner .wrapper .rgt{
    display: flex;
    position: relative;
    width: unset;
    padding: 0px;
    bottom: 0px;
  }
  .home-banner .wrapper .lft{
    position: relative;
    max-width: 50%;
    margin: 0px;
    justify-content: flex-start;
  }
  

  .home-banner {
    min-height: 130px;
    margin-bottom: 0px;
  }
  .card005-skeleton{
    height: 80px;
  }
  .FilterPanel .filter .rgt {
    height: 40px;
    margin-top: -7px;
    position: relative;
  }
  
  .FilterPanel .filter .rgt button {
      min-width: 70px;
      border-radius: 50px;
   
  }
  .myModal .errbody {
    padding: 20px 70px 40px 70px;
  }
  .myModal .btn {
    margin-bottom: 15px;
  }
  .MaxPlayer .btnsgroup button{
    height: 51px;
    width: 46px;
  }
  .MaxPlayer .btnsgroup .download-btn {
    width: 140px;
  }
  .banner01 .card .lft p time{
    font-size: 10px;
  }
  .banner01 .card .lft p:last-child{
    padding-top: 10px;
    margin-top: 10px;
  }
  .banner01 .card .lft p:nth-child(2){
    margin-bottom: 10px;
  }

  .banner01-skeleton .lft p{
    height: 12px;
  }
  
  .banner01 .card,
  .banner01-skeleton{
    height: 219px;
  }
  .banner01 .card,
  .banner01-skeleton{
    padding: 30px;
  }

  .banner01 .card .artwork,
  .banner01-skeleton .rgt {
    margin-left: 15px;
    height: 70px;
    width: 70px;
    min-width: 70px;
  }
  .banner01 .card .lft p:last-child{
    font-size: 12px;
  }
  .mynot .rgt time {
    font-size: 11px;
    min-width: 70px;
  }
  .mynot .lft .userpic {
    margin-right: 15px;
  }
  .userbanner02 .user .rt .details p {
    min-width: 101px;
  }
  .userbanner02 .user .rt .details p span {
    min-width: 30px;
  }
  .usercard03-skeleton .details p {
    margin-top: 12px;
    height: 11px;
  }
  .card002 .ppimg,
  .usercard03-skeleton .circle{
    width: 80px;
    height: 80px;
  }
  .usercard03-skeleton .box {
    height: 40px;
    margin-top: 12px;
  }
  .card05-skeleton .details p {
    height: 12px;
  }
  .card001 .details .stats .myicon{
    height: 25px;
  }
  .userbanner .background , .userbanner02 .background{
    border-top: 1px solid rgb(255 255 255 / 10%);
  }
  .MaxPlayer .albumactions .btns button,
  .loading-album-details .p1 div:last-child p {
      min-width: 130px;
  }
  .card004 .icon svg{
    width: 35px;
    height: 35px;
  }
  .MuiModal-root .viewImg .img{
    height: 400px;
    width: 400px;
  }
  .card004 .icon{
    margin: 35px;
  }
  .albumcard .contents .details p{
    margin-bottom: 3px;
  }
  .albumcard .contents .details p time{
    font-size: 12px;
  }
  
  .userbanner .kpis .kpi button::after{
    height: 40%;
  }
  .userbanner .kpis .kpi:last-child button::after{
    width: 1px;
  }
  .recoverpassword .email-sent svg{
    font-size: 80px;
  }
  .CommentCard .comment .reactbtns button p{
    font-size: 12px;
  }
  .CommentCard .comment .reactbtns button svg {
    width: 18px!important;
    height: 18px!important;
}
  .myform .btns button {
    width: unset;
  }
  .landingpage .background .logo{
    width: 140px; 
    margin: 30px;
  }
  .card13-skeleton .lft p{
    height: 11px;
  }
  .card13-skeleton{
    padding: 7px 0px;
  }
  .myfollower .lft .userpic {
    font-size: 17px;
}

  .CommentBox textarea{
    min-height: 120px;
  }
  .CommentBox button {
    line-height: 22px;
    font-size: 12px!important;
}
  .card007 .info .rgt button {
      min-width: 125px;
  }
  .myfilter .rgt button{
    min-width: 70px;
  }
  .MaxPlayer .btnsgroup svg{
    width: 20px;
    height: 20px;
  }

  .card005 .trackdetails .reacticons .icon p{
    font-size: 11px;
  }
  .card005 .trackdetails .reacticons .icon svg {
    font-size: 16px;
}
  .card005 .trackdetails .reacticons .icon:active{
    background-color: transparent
  }
  .card005 .trackdetails .reacticons .icon:hover{
    background-color: rgb(255 255 255 / 5%);
  }
  .card001 .details .stats .myicon .MuiCircularProgress-root{
    width: 15px!important;
    height: 15px!important;
  }
  .navbar .wrapper .rgt .useraccount .usericon img{
    width: 100%;
    height: 100%;
    min-width: 100%;
  }
  .card007 .info .lft .thumbnail,
  .card007-skeleton .circle{
    width: 90px;
    min-width: 90px;
    height: 90px;
  }
  .navbar .wrapper .rgt .useraccount .usericon::before{
    background-color: #000;
  }
  .completeprofile{
    margin-bottom: 20px;
  }
  .MaxPlayer .download-btn p{
    font-size: 12px;
  }
  .card001 .placeholder .type{
    margin: 15px;
  }
  .card001 .placeholder .type p{
    font-size: 12px;
  }
  .navbar .wrapper .rgt .useraccount.loggedin .details{
    display: block;
  }
  .navbar .wrapper .rgt .useraccount.notloggedin{
    width: 145px;
  }
  .navbar .wrapper .rgt .useraccount.loggedin {
    border: 1px solid rgb(255 255 255 / 10%);
    color: #000;
    padding-left: 4px!important;
    width: unset;
    min-width: unset;
    height: unset;
  }

  .navbar .wrapper .rgt .useraccount.notloggedin .details{
    display: block!important;
  }
  .navbar .wrapper .rgt .useraccount.loggedin .details p {
    padding-right: 20px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .navbar .wrapper .rgt .useraccount.loggedin .details p::before{
    position: absolute;
    left: 0px;
    content: "";
    height: 50%;
    width: 1px;
    background-color: rgb(255 255 255 / 15%);
  }
  .navbar .wrapper .rgt .useraccount .usericon{
    width: 28px;
    min-width: 28px;
    height: 28px;
    border: 0px;
  }
  .navbar .wrapper .rgt .useraccount{
    padding: 0px;
    display: flex;
    padding-right: 30px;
    align-items: center;
    border-radius: 50px;
    justify-content: flex-start;
    min-height: 36px;
    color: #fff;
  }
  
  .navbar .wrapper .rgt .useraccount .details{
    display: block;
  }
  .navbar .wrapper .rgt .useraccount .usericon{
    margin-right: 10px;
    border: 0;
  }
  .commentReply > .closebtn{
    margin: 20px;
  }
  .mymenucontainer button{
    border: 1px solid #2e2e2e;
    border-radius: 6px;
  }
  .commentReply {
      display: flex;
      align-items: flex-end;
      justify-content: center;
  }

  .commentReply > .contain {
    height: 70vh;
    border-radius: 20px 20px 0px 0px;
    max-width: 60%;
    margin: 0 auto;
    bottom: 0px;
    width: 100%;
  }
  .commentReply .CommentCard {
      padding:0px 10px;
  }
  .commentReply{
    width: calc(100% - 230px);
  }
  .sharelinkcontainer .contain button{
    height: 130px;
  }
  .userbanner .kpis .kpi p:nth-child(1) {
    font-size: 18px;
  }
  .card004 .contain .kpi p:first-child{
    font-size: 18px;
  }
  .myModal .container .drawercontainer{
    background-color: transparent;
    padding: 20px 50px 50px 50px;
  }
  .myModal .container .drawercontainer .myform textarea{
    padding: 20px!important;
    background-color: #0e0e0e;
  }
  .myModal .container .drawercontainer .btns button{
    width: 100%;
  }
  .myModal .container .drawercontainer .wrapper{
    padding: 0px;
  }
  .playerinfo .comments .wrapper{
    padding: 0px;
  }
 
  .FilterPanel .keywords button p{
    font-size: 12px;
  }
  .myModal .filter_container {
      padding: 25px 50px 50px 50px;
  }
  .myModal .header {
      display: flex;
      padding: 10px 50px 0px 50px;
      height: 98px;
  }
  .card007 .info{
    margin-bottom: 20px;
  }
  .card007-skeleton{
    margin-top: 0px;
    padding: 30px;
    height: 240px;
  }
  .CommentBox textarea {
    padding: 7px;
    padding-left: 60px;
    padding-right: 110px;
  }
  .CommentBox{
    padding: 0px;
  }

  .CommentCard .header .user .details p:nth-child(2){
    margin-top: 5px;
  } 
  .MaxPlayer .moretracks{
    padding: 0px;
  }
  .card13-skeleton .rgt p{
    min-width: 100px;
  }

  
  .card004 .contain{
    width: 85%;
    padding: 30px;
}

  .userbanner .lft .titles02 {
    margin-bottom: 20px;
  }
  .userbanner .lft .titles01 p{
    font-size: 16px;
  }
  .userbanner .lft .titles01 p:nth-child(2){
    font-size: 15px;
  
  }
  .uploadcomplete .banner {   
    padding: 20px 0px;
  }
  .MaxPlayer .banner .btns{
    margin: 0px auto;
  }
  .MaxPlayer .btns .playbtn svg {
    width: 40px;
    height: 40px;
  }
  .MaxPlayer .btns .playbtn svg {
      width: 35px;
      height: 35px;
  }
  .MaxPlayer .banner .btns button:first-child svg, .MaxPlayer .banner .btns button:last-child svg {
    height: 20px;
    width: 20px;
    min-width: 20px;
  }
  .MaxPlayer .btns .smallbtn svg {
    width: 40px;
    height: 40px;
  }

  .userbanner .lft .titles01{
    margin-bottom: 10px;
  }
  .userbanner .lft .titles01 p {
    font-size: 17px;
    line-height: 22px;
  }
  .card007 .stats .column p:first-child{
    font-size: 15px;
  }
  .FilterPanel .searchbar svg {
    display: none;
  }
  .FilterPanel .searchbar input {
    padding-left: 0px;
    height: 50px;
  }
  
  .card005 .rgt .playbtn,
  .card005-skeleton .rgt div {
    width: 70px;
    height: 70px;
    min-width: 70px;
  }
  .card007 {
    margin-top: 0px;
    padding: 30px;
    height: 240px;
  }
  .card007 .info .lft .thumbnail p {
    transform: scale(2);
  }

  .myModal .errbody p{
    margin-bottom: 15px;
  }
  .genreshome,
  .uploadmusic,
  .settingscontainer,
  .manageaccount,
  .manageuploads{
    padding-top: 20px;
  }
 
  .uploadmusic .uploadcover{
    margin-bottom: 15px;
  }
  .card05-skeleton{
    margin-bottom: 20px;
  }
 
  .userbanner .mypath{
    background-color: transparent;
    border-bottom: 0px;
    position: relative;
    z-index: 7;
  }
  .userbanner .mypath .wrapper button {
    background-color: rgb(255 255 255 / 9%);
  }
  .userbanner .mypath .wrapper button:hover{
    background-color: rgb(255 255 255 / 18%);
  }
  .albumcard{
    margin-right: 0px;
    width: 100%;
  }
  .myform .mylabel{
    min-height: 30px;
  }
  .uploadmusic .trackupload .upload input {
    height: 45px;
  }
  .uploadmusic .trackupload .upload .num{
    height: 45px;
    min-width: 45px;
    width: 45px;
  }
  .myform .MuiFormControl-root .MuiInputLabel-shrink {
    transform: translate(0, 12px) scale(1);
  }
  .card05-skeleton{
    width: 100%;
    margin-bottom: 20px;
  }
  .card05-skeleton::before{
    content: "";
  }
  .albumcard,
  .usercard03-skeleton,
  .card002{
    /* height: 219px; */

  }
  .usercard03-skeleton{
    margin-bottom: 20px;
  }
  .albumcard-skeleton {
      width: 100%;
      border-radius: 6px;
      overflow: hidden;
      margin-right: 30px;
      margin-bottom: 20px;
  }

  .albumcard-skeleton .tp {
    /* height: 151px; */
  }

  .rightmenu {
    padding-left: 30px;
    border-top: 0;
    padding-top: 0px;
    margin-top: 0px;
  }
  .rightmenu::before{
    content: "";
    width: 1px;
    height: 100%;
    background-color: rgb(255 255 255 / 15%);
    display: block;
    position: fixed;
    top: 0px;
  }
  .rightmenu .content-container{
    margin-left: 30px;
  }
  .rightmenu .content-container{
    
  }
  .uploadtips .list{
    margin-bottom: 15px;
  }
  .card001 .placeholder .playbtn button{
    width: 40px;
    height: 40px;
  }
  
  .MaxPlayer .timer .current,
  .MaxPlayer .timer .overall{
    font-size: 12px;
  }
  .uploadcomplete .banner .lft img{
    height: 160px;
    width: 160px;
    border-radius: 6px;
  }
  .uploadcomplete .contain h2{
    font-size: 14px;
  }
  .uploadcomplete .myform .MuiInputBase-root{
    height: 190px;
  }
  .MaxPlayer .albumactions img,
  .loading-album-details .p1 div:first-child p {
    width: 110px;
    height: 110px;
  }
  .MaxPlayer .albumactions {
    padding: 10px 0px;
  }
  .card005 .trackdetails .tracktitle p:first-child{
    margin-bottom: 3px;
  }
  .MaxPlayer .htitle{
    display: flex;
  }
  
  .no_data_msg{
    max-width: 450px;
  }
  .no_data_msg .mybtns {
      display: flex;
  }
  .no_data_msg .mybtns button:last-child {
      margin-left: 10px;
  }
  .card004{
    width: 100%;
  }
  .uploadmusic .upload-file .lft {
    min-width: 120px;
  }
  .imgcrop .ant-upload-list-item-actions button svg{
    font-size: 25px!important;
  }
  .ant-modal .ant-modal-content {
    max-width: 50%;
    margin: 0 auto;
    padding: 20px 24px;
  }
  .ant-modal .ant-modal-body {
    padding-bottom: 0px;
  }
  .ant-modal .ant-modal-footer {
    padding-top: 0px!important;
    margin-top: 0px;
  }
  .ant-modal-mask {
    background-color: rgb(0 0 0 / 60%)!important;
  }
  .ant-modal-wrap.img-crop-modal{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profilebanner .userpp,
  .imgcrop .ant-upload.ant-upload-select,
  .imgcrop .ant-upload-list-item-container,
  .imgcrop{
    font-size: 14px!important;
    width: 220px!important;
    height: 220px!important;
  }
  .myModal .errIcon svg{
    height: 60px;
    width: 60px;
  }

  .MaxPlayer .banner-b .trackdetails .songcover{
    height: 130px;
    max-width: 130px;
    min-width: 130px;
  }
  .addlyricscontainer, .deletesongcontainer{
    z-index: 9!important;
  }
  .deletesongcontainer .btns button{
    margin: 0px auto;

  }
  
  .addlyricscontainer .wrapper, .deletesongcontainer .wrapper{
    background-color: #181818;
    padding-top: 0px!important;
  }
  .addlyricscontainer .myform textarea{
    background-color: transparent!important;
    font-size: 17px!important;
  }

  .minPlayer .details a.titles p{
    font-size: 12px;
    line-height: 16px;
    
  }

 
 
  .playerinfo .comments .title{
  }
  .card005 .trackdetails .tracktitle p time {
    font-size: 12px;
  }
  .card005 .trackdetails .reacticons button{
    height: 30px;
  }
  .playerinfo .songdetails {
    padding-left: 0px;
  }
  .card005,
  .card005-skeleton{
    height: 80px;
  }
  .playerinfo .songdetails .htitle,
  .MaxPlayer .moretracks .htitle,
  .MaxPlayer .albumdetails .htitle{
    padding-top: 25px;
  }
  .MaxPlayer .banner-b .trackdetails .titles p{
    font-size: 14px;
  }
  .MaxPlayer .banner-b .trackdetails .titles p:last-child{
    font-size: 13px;
  }



  .MaxPlayer .path{
    padding-top: 15px;
  }
  .MaxPlayer .path .wrapper{
    display: flex;
    align-items: center;
  }
  .MaxPlayer .path button{
    color: #fff;
    margin-right: 5px;
    padding-left: 0px;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-start;
    min-width: 50px;
    transition: 0.2s;
  }
  .MaxPlayer .path button:hover{
    padding-left: 10px;
  }
  .MaxPlayer .path button:hover{
    background-color: rgb(255 255 255 / 10%)!important;
  }
  .MaxPlayer .path button svg{
    fill: #fff;
  }
  .MaxPlayer .timer{
    padding: 0px;
    padding-bottom: 15px;
  }
  .MaxPlayer .banner-b .trackdetails {
    padding: 0px 0px 0px 0px;
  }
  .MaxPlayer .path p{
    margin: 0px;
  }
  .MaxPlayer .path p a{
    color: inherit;
    font-weight: 600;
    color: inherit;
  }

 
  .landingpage .section{
    max-width: 550px;
    width: 100%;
    border: 1px solid rgb(52 52 52 / 37%);
    padding: 40px;
    border-radius: 6px;
    background-color: rgb(24 24 24 / 95%);
  }
  .landingpage {
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .landingpage .MuiTabs-flexContainer{
    display: block;
  }
  .notscontain .rgt button{
    font-size: 13px;
  }
  .card13 .rgt button{
    min-width: 100px;
  }
  
  .htitle button{
    min-width: 110px;
  }
  .mycontainer {
      padding-top: 75px;
      padding-bottom: 120px;
      max-width: 2500px;
      width: 100%;
      margin: 0px auto;
  }
  .htitle .lft p::after {
      /* content: "";
      height: 1px;
      background-color: #df1233;
      display: block;
      width: 60%;
      margin-top: 10px;
      border-radius: 50px;
      margin-bottom: 10px; */
  }
  .card004 .contain .kpi{
    margin: 20px;
  }

  .card004{
    height: 240px;
    margin-top: 10px;
  }
  .userbanner .icons button{
    min-width: 40px;
    height: 40px;
    width: 40px;
  }
  .card05 .contents{
    height: 220px;
  }
  .card001,
  .card002,
  .card05,
  .usercard03-skeleton,
  .card05-skeleton{
    width: 100%;
  }
  .userbanner .kpis .kpi p:nth-child(2){
    font-size: 12px;
  }
  .userbanner .kpisactionbtns{
    display: flex;
    align-items: center;
    /* background-color: rgb(24 24 24 / 50%); */
    padding-bottom: 10px;
  }
  .userbanner .kpisactionbtns .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    max-width: 810px;
    padding-top: 0px;
  }
  .userbanner .kpisactionbtns .kpis{
    border: 0px;
    padding-right: 20px;
    background: transparent;
    
  }
  .userbanner .kpisactionbtns .icons{
    margin-bottom: 0px;
    margin: 0px;
    justify-content: flex-end;
    max-width: unset;
  }
  .userbanner .kpisactionbtns .icons button{
    margin-bottom: 0px;
  }
  .userbanner .kpisactionbtns .icons > button:first-child{
    width: 100%;
    border-radius: 50px;
  }
  .userbanner .kpisactionbtns .kpis .kpi button {
    padding: 15px;
    display: flex;
  }
  .userbanner .kpisactionbtns .kpis .kpi p:first-child{
    margin-right: 10px;
  }
  .userbanner .kpis{
    height: unset;
    align-items: unsafe;
    margin-top: 0px;
  }
  .kpisactionbtns .rgt{
    width: 60%;

  }
  .card001 .details .titles p:last-child{
    font-size: 12px;
  }
  .card001 .details .stats .myicon p{
    font-size: 12px;
    line-height: 12px;
  }
  .card001 .details .stats .myicon svg{
    font-size: 16px;
  }
 
  .card001 .placeholder {

  }
  .card002 .details button {
    font-size: 12px!important;
  }
  .myModal .container{
    max-width: 540px;
  }

  .MaxPlayer{
    margin-top: 0px;
  }
  .genrecard{
    border-radius: 6px;
    height: 95px;
    margin-bottom: 0px;
  }

  .myform .btns button {
    min-width: 145px;
    height: 45px;
  }
  .settingscontainer .card09 .details {
      padding: 15px!important;
  }
  .settingscontainer .card09{
    margin-bottom: 15px!important;
    min-height: 110px!important;
  }
  .card05 {
    margin-right: 20px;
  }
  .userbanner .icons{
    justify-content: center;
    margin: 0px;
    margin-bottom: 20px;
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
    margin-bottom: 15px;
  }
  .userbanner .flexdv {
    padding: 0px;
  
    align-items: center;
    justify-content: center;
  }
  .userbanner {
    margin-bottom: 20px;
  }
  .userbanner .contain{
    text-align: center;
  }
  .albumcard .contents{
    width: 100%;
  }
  .albumcard .contents .songcover{
    /* height: 150px; */
  }
  .albumcontainer, .card05container{
    border-bottom: 0px;
  }
  .albumcard .contents .details,
  .albumcard-skeleton .bt{
    padding: 15px;
    height: 73px;
  }
  .sidebar{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 230px;
    height: 100vh;
    padding-left: 20px;
    border-right: 1px solid rgb(255 255 255 / 10%);
    overflow: hidden;
    z-index: 999;
  }
  .sidebar .background{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    animation-name: fx123;
    animation-duration: 1s;
    animation-iteration-count: initial;
    transition: 1s;
  }
  @keyframes fx123{
    0%{opacity: 0}
    100%{opacity: 1}
  }
  .sidebar .background .fademask{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #181818;
    z-index: 2;
    opacity: 0.85;
  }
  .sidebar .background img{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
    filter: blur(20px);
    transform: scale(1.3);
  }
  .sidebar .header{
    padding-bottom: 30px;
    padding-right: 0px;
    padding-top: 37px;
    border-bottom: 1px solid rgb(255 255 255 / 15%);
    margin-bottom: 15px;
    width: 100%;
  }
  .sidebar .header .brandlogo{
    width: 80%;
  }
  .sidebar .header p{
    font-size: 18px;
    color: #fff;
    font-weight: 300;
    margin: 0px;
    display: inline-block;
    border-bottom: 1px solid #343434;
    width: 100%;
    padding-bottom: 10px;
  }
  .sidebar .header p b{
    color: #df1233;
    font-weight: 800;
  }
  .sidebar .contain{
    height: 100vh;
    margin-right: 15px;
    position: relative;
    z-index: 5;
  }

  .sidebar .contain .menu .tabs{

  }
  .sidebar .contain .menu .tabs button{
    padding-left: 0px;
    text-align: left;
    text-transform: none;
    width: 100%;
    height: 37px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    color: #ccc;
    background-color: transparent;
    position: relative;
    padding-left: 18px;
    
  }
  
  .sidebar .contain .menu .tabs button:hover{
    background-color: rgb(255 255 255 / 10%);
  }
  .sidebar .contain .menu .tabs button::before{
    content: "";
    height: 50%;
    width: 6px;
    background: #fff;
    display: block;
    border-radius: 6px;
    margin-right: 10px;
    opacity: 0;
    transition: 0.4s;
    position: absolute;
    left: 0px;
  }
  .sidebar .contain .menu .tabs button.active::before{
    opacity: 1;
  }
  .sidebar .contain .menu .tabs button.active p{
    font-weight: 600!important;
  }
  .sidebar .contain .menu .tabs button p{
    margin: 0px;
    display: flex;
    align-items: center;
    color: #fff;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    opacity: 0.7;
    
  }
  .sidebar .contain .menu .tabs button .mybadge{
    position: absolute;
    right: -2px;
    top: -2px;
    width: 20px;
    height: 20px;
    background: #DF1233;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
  .sidebar .contain .menu .tabs button .mybadge p{
    color: #fff!important;
    font-weight: 600;
    line-height: 0px;
    margin: 0;
    overflow: unset;
    width: 100%;
    text-align: center;
    opacity: 1;
    font-size: 11px;
  }
  .sidebar .contain .menu .tabs button svg{
    font-size: 23px;
    color: #fff;
    fill: #fff;
    opacity: 0.3;
  }
  .sidebar .contain .menu .tabs button.active svg{
    opacity: 1;
  }

  .sidebar .contain .menu .tabs button.active p{
    font-weight: 600;
    color: #fff;
    opacity: 1;
  }
 
  .sidebar .contain .menu .tabs button{
    font-weight: 400;
  }
  .sidebar button span{
 
    font-weight: 400;
  }
  .sidebar button span a{
    color: #fff;
  }
  .sidebar .footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    padding-right: 15px;
    border-top: 1px solid rgb(255 255 255 / 15%);
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sidebar .footer p{
    font-weight: 400;
    margin: 0px;
  }
  .sidebar .footer p:first-child{
    font-weight: 400;
  }
  .sidebar .footer p a:last-child{
    color: #DF1233!important;
    font-weight: 500;
  }
  .myaccountmenu .MuiPaper-root{
    background-color: #282828;
  }
  .myaccountmenu .MuiMenuItem-root{
    display: flex;
    width: 100%;
    justify-content: flex-start;
    min-width: 200px;
    color: #fff;
    padding: 8px 20px;
  }
  .myaccountmenu .MuiMenuItem-root p{
    margin: 0px;
    color: #fff;
  }
  .myaccountmenu hr{
    border-color: rgb(255 255 255 / 12%);
  }
  .mycontainer{
    padding-left: 230px;
    padding-top: 56px;
  }

  .navbar{
    width: calc(100% - 230px)!important;
    right: 0px;
    left: unset!important;
  }
  .card002{
    padding: 15px;
    min-height: 219px;
  }
  .swiper .card002,
  .swiper .card001{
    margin-bottom: 0px!important;
  }
  .albumcard{
    margin-bottom: 20px;
  }
  .albumcard,.card002,.card001{
    margin-right: 30px;
}

  .wrapper{
    padding-left: 20px;
    padding-right: 20px;
  }
  .myform .btns button{
    font-size: 13px;
  }
  p,a,button,
  .myform .MuiInputBase-input, input, textarea{
    font-size: 13px;
  }
  button{
    font-size: 13px!important
  }
  .MaxPlayer .btnsgroup .action-btn p,
  .MaxPlayer .download-btn .downIcon p {
    font-size: 12px;
    line-height: 3px;
  }
  .MaxPlayer .btnsgroup {
    padding: 10px 15px!important;
  } 
 
}
@media (min-width:1600px) {
  .FilterPanel .filter .rgt button,
  .mynot .rgt time,
  .htitle p a,
  .myfilter .rgt button {
    min-width: 75px;
  }
  .myModal .container{
    max-width: 550px;
  }

  .htitle .lft p .platform{
    font-size: 12px;
  }
  .htitle p{
    font-size: 15px;
  }
  .userbanner .lft .titles02{
    max-width: 992px;
    margin: 0 auto;
    width: 100%;
  }
  section{
    margin-bottom: 25px;
  }
  .banner01 .card .lft p {
    font-size: 18px;
  }

  .profilebanner .userpp,
  .imgcrop .ant-upload.ant-upload-select,
  .imgcrop .ant-upload-list-item-container,
  .imgcrop{
    width: 250px!important;
    height: 250px!important;
  }
  .home-banner .wrapper .lft p{
    font-size: 18px;
  }
  .sidebar .contain .menu .tabs button .mybadge p{
    font-size: 12px;
  }
  .card005,
  .card005-skeleton{
    height: 90px;
  }
  .myModal .btn {
    height: 50px;
  }
  .MaxPlayer .btnsgroup button {
    height: 50px;
  }
  .banner01 .card .lft p time{
    font-size: 12px;
  }
  .banner01 .card{
    padding: 30px;
  }
  .banner01 .card .lft p:last-child{
    font-size: 13px;
  }
  .FilterPanel .keywords button p{
    font-size: 13px;
  }
  .usercard03-skeleton .details p {
      height: 13px;
  }
  .card05-skeleton .details p {
    height: 13px;
    margin-top: 13px;
}
  .albumcard-skeleton .bt p:first-child{
    margin-top: 8px;
  }
  .MaxPlayer .albumactions .btns button,
  .loading-album-details .p1 div:last-child p {
    min-width: 150px;
  }
  .sidebar .contain .menu .tabs button .mybadge{
    height: 23px;
    width: 23px;
    right: -3px;
    top: -3px;
  }
  .card001 .details .titles p time{
    font-size: 12px;
  }
  .landingpage .section {
    max-width: 600px;
    padding: 50px;
  }
  .landingpage .background .logo{
    width: 180px; 
    margin: 40px;
  }
  .card13-skeleton{
    padding: 10px 0px;
  }
  .card004{
    height: 270px;
  }
  .card007 .info .rgt button {
    min-width: 130px;
  }
  .completeprofile{
    margin-bottom: 30px;
  }
  .MaxPlayer .download-btn p{
    font-size: 13px;
  }
  .commentReply .CommentCard {
      padding: 0px 30px;
  }
  .commentReply{
    width: calc(100% - 250px);
  }
  .card007 .info .lft .thumbnail {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }
  .uploadcomplete .banner {
    padding: 20px 0px;
  }
  .mypath{
    padding: 12px 0px;
  }
  .userbanner .lft .titles01 p {
    font-size: 19px;
    line-height: 25px;
  }
  .card007 .stats .column p:first-child{
    font-size: 16px;
  }
  .navbar{
    width: calc(100% - 250px)!important;
  }
  .usercard03-skeleton, .card002{
    margin-bottom: 30px;
  }
  .card007 .info .lft .thumbnail p {
    transform: scale(2.3);
  }
  .card007 .info .lft .thumbnail,
  .card007-skeleton .circle {
      width: 110px;
      min-width: 110px;
      height: 110px;
  }

  .card007,
  .card007-skeleton {
    padding: 40px;
    height: 271px;
  }

 
  .card005 .rgt .playbtn,
  .card005-skeleton .rgt div {
    width: 75px;
    height: 75px;
    min-width: 75px;
  }
  .genreshome,
  .uploadmusic
  .settingscontainer,
  .manageaccount,
  .manageuploads{
    padding-top: 30px;
  }
  .uploadmusic .uploadcover{
    margin-bottom: 20px;
  }
  .addlyricscontainer .myform textarea{
    font-size: 20px!important;
  }

  .MaxPlayer .btnsgroup {
    padding: 10px 20px!important;
  } 
  .MaxPlayer .timer .current,
  .MaxPlayer .timer .overall{
    font-size: 13px;
  }
  .uploadcomplete .contain h2{
    font-size: 15px;
  }

  .MaxPlayer .banner-b .trackdetails .songcover{
    height: 170px;
    max-width: 170px;
    min-width: 170px;
  }
  .MaxPlayer .albumactions img,
  .loading-album-details .p1 div:first-child p{
    width: 130px;
    height: 130px;
  }
  .no_data_msg{
    max-width: 500px;
  }
  .uploadmusic .upload-file .lft {
    min-width: 130px;
  }
  
  .ant-modal .ant-modal-content {
    max-width: 40%;
  }
  .ant-modal .ant-modal-footer button{
    font-size: 15px!important;
  }
  .minPlayer .details a.titles p{
    font-size: 13px;
  }
  .playerinfo .songdetails {
    padding-left: 0px;
  }
  .MaxPlayer .banner-b .trackdetails .titles p{
    font-size: 15px;
  }
  .MaxPlayer .banner-b .trackdetails .titles p:last-child{
    font-size: 14px;
  }

 

  .playerinfo .MuiTabs-root button{
    font-size: 14px;
  }
  .sidebar .header p{
    padding-bottom: 15px;
  }

  .card002 .details button {
    font-size: 13px!important;
  }
  .card004 .contain{
    width: 80%;
  }
  .userbanner {
    margin-bottom: 30px;
  }
  .userbanner .kpis .kpi p:nth-child(2){
    font-size: 13px;
  }
  
  .userbanner .lft .titles02 {
    margin-bottom: 15px;
  }
  .userbanner .kpisactionbtns .wrapper{
    max-width: 880px;
  }
  .userbanner .kpisactionbtns .kpis .kpi button {
    padding: 18px;
  }


  .mycontainer{
    padding-left: 250px;
  }
  .sidebar{
    width: 250px;
  }
  .settingscontainer .card09 .details {
      padding: 20px!important;
  }
  .settingscontainer .card09{
    margin-bottom: 20px!important;
    min-height: 120px!important;
  }
  .card05 {
    margin-right: 30px;
  }
  .albumcard,.card002,.card001{
    margin-right: 30px;
    margin-bottom: 30px;
  }
  .albumcard{
    margin-bottom: 30px;
  }
  .albumcard-skeleton{
    margin-bottom: 30px;
  }
  
  .sidebar{
    padding-left: 30px;
  }
  .wrapper{
    padding-left: 30px;
    padding-right: 30px;
  }
  .notscontain .rgt button{
    font-size: 14px;
  }
  .myform .btns button{
    font-size: 14px;
    height: 50px;
  }

  p,a,button,
  .myform .MuiInputBase-input, input,textarea{
    font-size: 14px;
  }
  button{
    font-size: 14px!important
  }
  .card002 {
    width: 100%;
    margin-bottom: 30px;
  }
  
 
}

@media (max-width: 992px) {
  ::-webkit-scrollbar {
      -webkit-appearance: none;
      display: none;
  }
  .mycontainer.musicplayer{
   padding-top: 0px;
  }

  .MaxPlayer{
    margin-top: 0px;
    border-radius: unset;
  }
  
  .MaxPlayer .banner {
    border-radius: unset;
  }
  .userbanner {
    margin-bottom: 20px;
}
  .albumcontainer::-webkit-scrollbar, 
  .vertical-container::-webkit-scrollbar,
  .myModal .filter .options::-webkit-scrollbar,
  .card05container::-webkit-scrollbar,
  .myform .selectoptions::-webkit-scrollbar{
    -webkit-appearance: none;
    display: none;
  }
  .albumcontainer,
  .vertical-container,
  .myModal .filter .options,
  .card05container,
  .myform .selectoptions{
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
}

.settingscontainer{
  padding-bottom: 100px;
}


.settingscontainer .card09{
    min-height: 100px;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 0px;
    transition: 0.2s;
    position: relative;
    display: block;
    width: 100%;
    color: #ccc;
    border: 1px solid #282828 !important;
}
.settingscontainer .card09:hover{
  background-color: #212121!important;
}
.settingscontainer .card09 .details{
  padding: 12px;
  text-align: left;
}
.settingscontainer .card09 .details p{
  text-transform: none;
  margin: 0px;
  font-weight: 400;
}
.settingscontainer .card09 .details p:first-child{
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

.settingscontainer .card09 .details .action{
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 10px;
}


.addlyricscontainer,.deletesongcontainer{
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  height: 100vh;
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;

}
.addlyricscontainer
.addlyricscontainer .myform{
  padding: 0px;
}
.addlyricscontainer .txtarea{
  background-color: #181818;
  padding-top: 0px;
}
.addlyricscontainer .mycontainer{
 padding-top: 56px;
}
.addlyricscontainer .myform textarea{
  background-color: #101010;
  width: 100%;
  height: 100vh;
  overflow: scroll;
  border: 0px;
  text-align: center;
  color: #fff;
  padding: 10px;
  max-height: unset;
  padding-bottom: 200px!important;
  font-size: 13px;
  margin-top: 0px;
  padding-top: 20px!important;
}
.deletesongcontainer{
  width: 100%;
  height: 100vh;
  
}
.deletesongcontainer .myform{
  height: 80vh;
  min-height: 350px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  justify-content: center;
  padding: 20px;
}
.deletesongcontainer .myform .icon svg{
  margin: 0 auto;
  display: block;
  font-size: 80px;
  color: #DF1233
}
.deletesongcontainer .myform .myerr p{
width: 100%;
}
.deletesongcontainer .titles{
text-align: center;


}
.deletesongcontainer .titles p {
  color: #fff;
  display: block;
  font-weight: 600;
  margin: 0px;
}
.deletesongcontainer .titles p:last-child{
  font-weight: 400;
  color: #aaa;
}

.drawercontainer{
  background-color: #212121;
  padding-bottom: env(safe-area-inset-bottom);
  
}
.mymenucontainer .mymenu{
  display: flex;
  align-items: center;
  justify-content: center;
}
.mymenucontainer button{
  width: 100%;
  height: 120px;
  margin-right: 10px;
}
.mymenucontainer button:last-child{
  margin-right: 0px;
}
.mymenucontainer .icon{
width: 50px;
min-width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
border: 1px solid #3e3e3e;
border-radius: 50%;
margin: 0 auto;
margin-bottom: 10px;
}
.mymenucontainer svg{
  color: #fff;
  fill: #fff;
      
}
.mymenucontainer span{
  color: #aaa;
  display: block;
}

.drawercontainer .navmenu{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
}
.drawercontainer .navmenu .lft{
  display: flex;
  align-items: center;
}
.drawercontainer .navmenu .lft .title{
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}

.drawercontainer .navmenu .lft button svg{
  color: #fff;
}
.drawercontainer .navmenu .rgt button svg{
  color: #DF1233;
}
.drawercontainer .navmenu button{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #212121;

}
.drawercontainer .navmenu .rgt button{
  background-color: transparent;
}
.drawercontainer .navmenu .rgt .deletebtn{
  padding: 10px;
  background-color: #e01233;
  color: #fff;
  border-radius: 3px;
  transition: 0.1s;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.drawercontainer .navmenu .rgt .deletebtn .MuiCircularProgress-root{
  width: 15px!important;
  height: 15px!important;
  margin-left: 5px;
}
.drawercontainer .navmenu .rgt .deletebtn:hover{
  opacity: 0.8;
}
.drawercontainer .myform{
padding-bottom: 25px;
}
.drawercontainer .myform textarea{
  color: #fff;
  padding: 10px!important;
  background-color: #181818;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  border: 0px;
  margin-bottom: 0px;
  border-radius: 6px;
  max-height: 300px;
  border: 1px solid transparent;
  margin-top: unset;
}
.drawercontainer .myform textarea.active{
  border: 1px solid #DF1233;
}
.drawercontainer form .myerr p.noerr {
  width: 100%;
}
.sharelinkcontainer{
  position: relative;
  z-index: 2;
}
.sharelinkcontainer textarea{
  position: absolute;
  opacity: 0;
  bottom: 0;
  left: 0;
}
.linktitle{
  padding: 0px 10px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 3px;
}
.linkpar{
  padding: 0px 10px;
  color: #aaa;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0px;
  margin-bottom: 10px;
  color: #DF1233;
  cursor: pointer;
  position: relative;
  width: 100%;
}
.linkpar span{
  display: block;
  color: #fff;
  border-radius: 3px;
  padding: 5px 10px;
  position: absolute;
  background-color: #DF1233;
  top: -28px;
  right: 10px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 55%);

}
.linkpar a{
  color: inherit!important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: 100%;
}
.sharelinkcontainer .contain{
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sharelinkcontainer .contain a{
  width: 100%;
  margin-right: 10px;
}
.sharelinkcontainer .contain a:last-child{
  margin-right: 0px;
}
.sharelinkcontainer .contain button{
  border: 1px solid #2e2e2e;
  height: 112px;
  width: 100%;
  color: #fff;
  border-radius: 6px!important;
  display: block;
}


.sharelinkcontainer .contain button .icon svg{
  font-size: 35px;
}
 .sharelinkcontainer .contain button p{
    display: block;
}

.MuiModal-root .viewImg{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  max-width: 500px;
  margin: 0px auto;
  animation-name: viewimgfx;
  animation-duration: 0.4s;
  animation-iteration-count: initial;
  transition: .4s;
}
@keyframes viewimgfx{
  0%{opacity: 0}
  100%{opacity: 1}
}

.MuiModal-root .viewImg .fademask{
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background-color: #000;
  opacity: 0.7;
  z-index: 2;
  top: 0px;
  left: 0px;
}
.MuiModal-root .viewImg .bgimg{
  position: absolute;
  width: 100%;
  min-height: 100vh;
  transform: scale(1.3);
  filter: blur(15px);
  z-index: 1;
  animation-name: viewimgfx2;
  animation-duration: 0.4s;
  animation-iteration-count: initial;
  transition: .4s;
  object-fit: cover;
  object-position: center;
}
@keyframes viewimgfx2{
  0%{transform: scale(1.2)}
  100%{transform: scale(1.3)}
}
.MuiModal-root .closeIcon{
  position: absolute;
  z-index: 4;
  top: 0px;
  right: 0px;
  margin-top: env(safe-area-inset-top);
  width: 45px;
  height: 45px;
  min-width: 45px;
  color: #fff;
  margin: 20px;
}
.MuiModal-root .closeIcon svg{
  fill: #fff;
  font-size: 27px;
}


.notice-card{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 70px;
  border-top: 1px solid #DF1233;
  padding: 0px 10px;
  padding-bottom: env(safe-area-inset-bottom);
}


.notice-card .lft p{
  color: #fff;
  font-weight: 600;
  margin: 0;
}
.notice-card .lft p:last-child{
  font-weight: 400;
  color: #aaa;
}

.notice-card .rgt button{
  background-color: #DF1233!important;
  color: #fff!important;
  border-radius: 50px!important;
  text-transform: capitalize;
  min-height: 37px;
  min-width: 95px;

}

@media (max-width: 360px) {
  .card007 .info {
    display: block;
  }
  .card007 .info .rgt button {
    margin-top: 15px;
    width: 100%;
  }
  .card007 {
    height: unset;
  }
  .minplayer-skeleton .lft div:first-child p{
    width: 45px;
    min-width: 45px;
    height: 45px;
  }
  .minplayer-skeleton .lft div:first-child p:last-child{
    width: 34px;
    min-width: 34px;
    height: 34px;
  }
  .passwordfield .icon{
    font-size: 11px;
  }
  .mymenucontainer .icon {
    width: 45px;
    min-width: 45px;
    height: 45px;
  }
  .mymenucontainer span {
    font-size: 11px;
  }
  .no_data_msg button{
    height: 41px;
  }
  .no_data_msg button span {
    font-size: 11px!important;
  }
  .myModal .btn {
    width: 100%;
    height: 41px;
  }
  .myModal button span {
    font-size: 11px!important;
  }
  .myModal .errIcon svg{
    height: 35px;
    width: 35px;
  }
  .navigationmenu .logoutbtn {
    padding: 8px 20px;
  }
  .navbar .MuiBadge-badge {
    font-size: 10px;
  }
  .navbar .MuiBadge-badge, .navigationmenu .MuiBadge-badge{
    font-size: 10px;
  }
  .navigationmenu .contents .profile .userpic{
    width: 100px;
    height: 100px;
    min-width: 100px;
    margin-bottom: 12px;
  }
  .navigationmenu .contents .profile .titles p {
    font-size: 14px;
  }
  .navigationmenu .contents .profile .titles p:nth-child(2) {
    font-size: 12px;
  }
  .navigationmenu .contents {
    /* margin-top: 60px; */
  }



  .navigationmenu .contents button .title{
   
  }
  .navigationmenu .logoutbtn p{
    font-size: 12px!important;
  }
  
  .albumcard .contents .details p{
    font-size: 11px;
  }
  .card005 .trackdetails .tracktitle p{
    font-size: 10px;
  }
  
  .CommentBox form .rgt button {
    padding: 10px 7px;
}
  .card005 .trackdetails .reacticons button{
    min-width: 32px;
  }
  .card005 .rgt .playbtn{
    width: 60px;
    height: 60px;
    min-width: 60px;
  }
  .completeprofile .card04{
    padding:10px;
    min-height: 130px;
  }
  .MaxPlayer .timer {
    padding: 0px 10px 10px 10px
  }
  .myform .btns button {
    height: 45px;
  }
  .myModal .header{
    height: 89px;
  }
  .myModal .filter_container {
    padding: 25px 15px;
  }
  .profilebanner .userpp,
  .imgcrop .ant-upload-list-item-container, 
  .imgcrop .ant-upload.ant-upload-select, 
  .imgcrop {
      height: 180px!important;
      width: 180px!important;
      min-width: 180px!important;
  }
  .ant-modal .ant-modal-footer {
    margin-top: 0px;
    padding: 0px 15px!important;
  }
  .profilebanner .ppimage{
    width: 121px;
    min-width: 121px;
    height: 121px;
    font-size: 30px;
  }
   .MaxPlayer .btnsgroup .action-btn {
    min-width: 37px;
  }
  .minPlayer,
  .minPlayer .details a.titles,
  .notice-card,
  .minplayer-skeleton {
    min-height: 68px;
  }
  .settingscontainer{
    padding: 10px;
  }
  .settingscontainer .row{
    display: block;
  }
  
  .settingscontainer .row .card09{
    width: 100%!important;
    margin: 0px;
    margin-bottom: 10px;
  }
  .uploadcomplete .banner .lft img{
    height: 100px;
    width: 100px;
  }

  
  .uploadcomplete .contain .htitle p {
    font-size: 13px;
  }
  .uploadcomplete .contain .shareplatforms .card06 svg {
    font-size: 34px;
  }
  .uploadcomplete .contain .shareplatforms .card06{
    height: 130px;
  }
  .uploadcomplete .banner .rgt button{
    width: 100px;
    height: 36px;
  }
  .uploading-container .contain p {
    font-size: 14px;
  }
  .uploading-container .songart {
    height: 140px;
    width: 140px;
  }
  .addlyrics button {
    min-width: 110px;
  }
  .addlyrics button span{
    font-size: 10px;
  }

  .uploadtips .num {
    width: 31px;
    height: 31px;
    min-width: 31px;
    font-size: 12px;
  }
  .uploadmusic .chooseupload button p {
      font-size: 11px;
  }

  .myModal .filter .options p{
    height: 40px;
  }
  .uploadmusic .chooseupload button svg {
      width: 25px;
      height: 25px;
  }
  .myform .selectoptions p{
    height: 40px;
  }
  .card002 .details button {
    font-size: 11px;
  }
  .card002 {
    height: 202px;
  }

  .genrecard .title p{
    font-size: 12px;
  }


  .navbar .searchinput input{
    font-size: 12px;
  }
  .card05 .play button svg{
    width: 30px;
    height: 30px;
  }
  .card05 .contents .details span {
    font-size: 11px;
  }
  .card05 .contents .details span:nth-child(2) {
      margin-top: 2px;
      font-size: 10px;
  }
  .MaxPlayer .banner-b .trackdetails .titles p {
    font-size: 12px;
  }
  .MaxPlayer .btns .playbtn svg {
      width: 35px;
      height: 35px;
  }
  .MaxPlayer .btns .smallbtn svg {
    width: 30px;
    height: 30px;
    
  }
  .CommentBox form .lft textarea {
    font-size: 12px;
  }
  .playerinfo header button span {
      font-size: 11px;
  }
 
  .CommentCard .comment .reactbtns button {
    min-width: 37px;
  }


  .CommentCard .header .user .ppimg{
    width: 39px;
    min-width: 39px;
    height: 39px;
  }
  .CommentCard .comment .para {
      display: inline-block;
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
  }
  .CommentCard .comment .para time {
      font-size: 9px;
  }
  
.playerinfo .comments .title span {
  font-size: 11px;
}
.commentReply > .closebtn {
  margin: 10px;
  width: 40px;
  height: 40px;
}
.commentReply > .closebtn svg{
  font-size: 20px;
}
.commentReply > .contain {
  margin-top: 70px;
}
.landingpage .btns button {
  height: 50px;
}
.landingpage .btns span {
    font-size: 12px;
}

.landingslide .details .title span {

    font-size: 25px;
}
.landingslide .details .title span:nth-child(2){
    font-size: 13px;
}
.landingpage .MuiTabs-root button span {

    font-size: 13px;
}

.card05 .contents{
  width: 140px;
  height: 180px;
}
.genrecard{
  min-height: 95px;
  padding: 15px;
  padding-right: 10px;
}
.CommentBox form .rgt button span {
    font-size: 10px;
}


.minPlayer .min-container{
  padding: 0px 5px;
}
.minPlayer .min-container .rgt img {
  width: 50px;
  height: 50px;
  min-height: 50px;
  min-width: 50px;
  margin-left: 5px;
}
.minPlayer .details .btns button {
  margin-right: 5px;
  width: 34px;
  min-width: 34px;
  height: 34px;

}


.minPlayer .details .btns .playbtn {
  border-radius: 45px;
  height: 45px;
  min-width: 45px!important;
  width: 45px;
}
.minPlayer .details .btns .playbtn svg{
  font-size: 20px;
}
.MaxPlayer .banner-b .trackdetails .songcover{
  height: 80px;
  max-width: 80px;
  min-width: 80px;
}
.myDrawer .MuiListItem-root{
  padding-top: 5px;
  padding-bottom: 5px;
}
.MuiAppBar-root .MuiIconButton-root {
  margin-left: 0px;
}

.userbanner .flexdv {
  padding: 25px 10px 20px 10px;
}


.userbanner .icons button svg {
  width: 18px;
  height: 18px;
}
.userbanner .icons button {
  min-width: 40px;
  height: 40px;
  width: 40px;
}
.MuiToolbar-gutters {
  padding-left: 10px;
  padding-right: 10px;
}
.userbanner .rgt .ppimg {
  width: 110px;
  min-width: 110px;
  height: 110px;
}

.userbanner .kpis .kpi button {
  padding: 5px 15px;

}

.mynot .lft .details svg {
  font-size: 14px;
}


.mynot .lft .userpic{
  width: 47px;
  min-width: 47px;
  height: 47px;
}
.navbar .wrapper .icon {
  width: 40px;
  height: 40px;
  min-width: 40px;
}
.navbar .wrapper .icon svg {
  font-size: 25px;
}
.MaxPlayer .albumactions img{
  width: 80px;
  height: 80px;
}
.card005 .trackdetails .tracktitle{
  font-size: 10px;
}
.MaxPlayer .albumactions .btns button {
  max-width: 114px;
  padding: 0px 8px;
}
.no_data_msg .icon img {
  max-width: 201px;
}
.card13 .lft .title {
  font-size: 11px;
}
}