input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
-webkit-box-shadow: 0 0 0 1000px #101010 inset !important;
-webkit-text-fill-color: #aaa !important;
}
.MuiCircularProgress-root{
  color: #fff!important;
}
.MuiButton-containedPrimary{
  background-color: #DF1233!important;
}
body{
  background: #181818!important;
}
.bgColor1{
  background-color: #DF1233!important;
  color: #fff!important;
}
.textColor1{
  color: #DF1233;
}
.bgColor2{
  background-color: #2B292C;
  color: #fff!important;
}
.textColor2{
  color: #2B292C;
}
.bgColor3{
  background-color: #E3E3E3;
  color: #2B292C!important;
}
.textColor3{
  color: #E3E3E3;
}
.bgColor4{
  background-color: #6C6C6C;
}
.textColor4{
  color: #6C6C6C;
}
.bgColor5{
  background-color: #F4F4F4;
}
.textColor5{
  color: #F4F4F4;
}






.navbar .MuiBadge-badge,.navigationmenu .MuiBadge-badge{
  background-color: #DF1233;
}

.navbar .searchinput input:focus{

  background-color: #181818;
}


.navbar .searchinput input{
  background-color: transparent;
  color: #ffff;
}
a{
 
}
.NavBanner .user span {
  color: #fff;
}
/* SlideShow */
.lbanner{
  background-color: #1E1D1F;
}
.lbanner .fade{
  box-shadow: inset -1px -60px 30px 0px #181818;
}
.landingslide .awssld__bullets .awssld__bullets--active {
  background: #ffffff;
  
}

.landingslide .details .title span{
  color: #fff;
}








/* MinPlayer */

.minPlayer{
background-color: #212121;
}

.minPlayer .details .btns .skipBtn{

}
.minPlayer .details .btns .skipBtn svg {
  color: #fff;
}
.minPlayer .details .btns .playbtn{

}
.minPlayer .details .btns .playbtn svg{
  color: #fff;
}
.minPlayer .details .btns .active{
  border: 1px solid #DF1233;
  background-color: #DF1233;
}
.minPlayer .details .btns .active svg{
  color: #fff;
}

.minPlayer .details a.titles p{
color: #fff;
}
.minPlayer .details a.titles p:last-child{
color: #DF1233;
}
.minPlayer .details a.titles:active,
.minPlayer .details a.titles:hover{
  background-color: #181818;
}
/* MaxPlayer */
.MaxPlayer{
  background-color: #181818;
}








/* PlayerInfo */

.playerinfo .details .par .active{
font-weight: 600;
color: #DF1233;
}
.playerinfo .details, .MaxPlayer .albumtracks .sec3{
border-color: #b9b8b8;
}

.playerinfo .details .descr{


background-color: #e0e0e0;
}
.playerinfo .comments .title{
  color: #aaa;
  
}
.playerinfo .songdetails {
  border-bottom: 1px solid #282828!important;
}

.playerinfo .songdetails .lyrics{
  color: #aaa;
  border-color: #323232;
}


.playerinfo .songdetails .descr{
  color: #aaa;
}
.playerinfo .songdetails .descr a{
  color: #DF1233!important;
}

/* Comments */

.CommentCard {
background-color: transparent!important;
border-bottom: 1px solid #282828;
}
.CommentCard .header .user .ppimg{
background-color: #2a2a2a;
color: #fff;
}

.CommentCard .comment .reactbtns button p, .CommentCard time,
.CommentCard .header .user .details p:last-child{
color: #aaa;
}

.CommentCard .header .user:active{
  background-color: #212121;
}



.CommentCard .header .user .details p{
  color: #fff;
}

.CommentCard .comment .para{
  color: #fff;
}
.CommentCard .comment .para a{
  color: #DF1233!important;
}

.CommentCard button svg{
  color: #767676;
}




.CommentCard .MuiAvatar-root{
background-color: #bfbfbf;
}
/* CommentBox */



.CommentBox form .lft .active {
  border-color: #e01233;
}


/* Upload Music */
.MuiLinearProgress-colorPrimary {
  background-color: rgb(0 0 0 / 0%)!important;
}
.MuiLinearProgress-barColorPrimary {
  background-color: #e01233!important;
}
.MuiLinearProgress-dashedColorPrimary {
  background-image: radial-gradient(rgb(255 255 255) 0%, rgb(255 255 255) 23%, rgb(0 0 0 / 0%) 0%)!important;
}
.uploadmusic .uploadcover .titles{
  color: #fff;
}

.uploadmusic .chooseupload button p{
color: #fff;
}
.uploadmusic .chooseupload button svg{
color: #DF1233;
}
.uploadmusic .uploadcover .artcover .MuiAvatar-root{
  background-color: #FFFFFF;
}
.uploadtips .num{
background: #DF1233;
color: #fff;
}

.uploadmusic form .fields .MuiFormControl-root .MuiInputLabel-shrink, .flexlabel{
color: #df1233;
}
.MuiFormLabel-root.Mui-error {
  color: #DF1233!important;
}
.MuiInputBase-root::after{
  border-bottom: 2px solid #DF1233!important;
}
.MuiInputBase-root.Mui-focused::after{
  border-bottom: 2px solid #DF1233!important;
}

.uploadmusic form .MuiInputBase-input {
color: #2B292C;
}






.uploadmusic .trackupload  .uploaded  button svg{
color: #DF1233;
}




.ant-modal .antd-img-crop-control button {

background: #212121!important;
color: #fff!important;
}

.ant-modal .ant-slider-track{
background-color: #DF1233!important;
z-index: 1;
}
.ant-modal .ant-slider-step {
  background-color: #424242!important;
}
.ant-modal .ant-slider-handle.ant-tooltip-open {
border-color: #DF1233!important;
}
.ant-slider:hover .ant-slider-track {
background-color: #DF1233!important;
}
.ant-modal .ant-modal-header {
  background-color: transparent!important;
  background: transparent!important;
  border-bottom: 0;
}
.ant-modal .ant-modal-footer button {
  background-color: #212121;
  color: #fff;
}
.ant-modal .ant-modal-footer button:last-child{
  background-color: #DF1233!important;
  color: #fff!important;
}
.ant-modal .ant-modal-footer button:last-child:active{
  background-color: #cb0021!important;
  color: #fff!important;
}
.ant-modal .ant-modal-footer button:active {
  background-color: #383838;
  
}
.ant-modal .img-crop-control button:active{
  opacity: 0.7;
}
.ant-modal .ant-modal-footer {

  border-top: 0;

}
.ant-modal .img-crop-control button{
  background-color: #212121!important;
  color: #fff!important;
}
.ant-modal .ant-modal-title {

color: #fff!important;
}
.ant-modal svg {

color: #fff!important;
}






.userbanner .icons > button.active:first-child .MuiButton-label{
  color: #DF1233;
}

.userbanner .kpis .kpi p {

color: #fff;
}



.profilebanner .ppimage{
  color: #fff;
  background-color: #212121;
}



.htitle button{
  background-color: #DF1233!important;
  color: #fff;
}



/* card05 */
.card05 {
  background-color: #181818;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 2%);
}
.card05 .contents .loadmore{
  background-color: #f30c2b;
  color: #fff;
}
.card05 .fademask{
  box-shadow: inset -1px -90px 30px 0px #000000;
  

}

.card05 .play button{
  background-color: #212121;
}
.card05 .play button svg{
  color: #fff;
}

.card05 .play .active{
  opacity: 0.9;
  background-color: #DF1233;
}
.card05 .play .active svg{
  color: #fff;
}
/* Followers */

 .myfollower{
border-bottom:1px solid #282828;
}




.myfollower .lft .userpic{
  background-color: #212121;
  color: #fff;
  
}
.myfollower .rgt button{
  background-color: #282828;
  color: #fff;
}
.myfollower .rgt button svg{
  color: #fff;
}

.myfollower .rgt .active{
}
/* Notification  */



.mynot.active {
  /* background-color: #212121; */
}


.mynot.active .details svg{
  color: #DF1233;
}
.notcontainer .lft svg{
color: #fff;
}
.notscontain .mynot{
  border-bottom: 1px solid #2a2a2a;
}
.notscontain .details p {
  color: #fff;
}
.notscontain .details p:nth-child(2) {
  color: #aaa;
}
.notcontainer .lft .userpp{
  background-color: #353535;
  color: #ccc;
}
.notcontainer .lft .userpp svg{
  background-color: #fff;
}
.notscontain .mynot .MuiCheckbox-root svg{
color: #DF1233
}
/* Recommended users */

.recommendedusers .details button.active {
background-color: #DF1233!important;


}


/* Form */
form .myerr{
  
  color: #DF1233;
}
form .myerr .noerr{
  
  color: #fff;
}
form .myerr .MuiCircularProgress-root svg{
  color: #fff;
}
form .myerr button{
  
  color: #fff;
}
/* Search Suggestions */
.searchSuggestions{
    background: #181818;
}
.searchSuggestions button span{
  color: #fff;
}
/* Error Modal */

.myModal .errIcon{
  background: #212121;
}

.myModal .errIcon svg{
  color: white;
}
.myModal .btn{
  background-color: #DF1233!important;
  color: #fff;
}
.myModal .closebtn{
  background-color: transparent!important;
  border: 1px solid #DF1233;
  color: #fff;
}
.myModal .header button svg{
  color: #fff;
}


/* Filter Modal*/
.myModal .header{
background-color: #212121;
}
.myModal .header span{
  color: #fff;
  }
.myModal .filter label{
  color: #aaa;
}


.myform .MuiFormControl-root {
  background-color: #101010;
}

.myform .btns button{
  background-color: #DF1233;
  color: #fff;
}

.myform .MuiFormControl-root label {
  color: #aaa;
}
.myform .MuiInputBase-input{
  color: #fff!important;
}
/* No Results */
.no_data_msg p{
  color: #aaa;
}



/*Upload Complete*/
.uploadcomplete .contain .shareplatforms .card06{
    background-color: #212121;
}


.settingscontainer .card09:active{

opacity: 0.7;

}


.settingscontainer .card09 .details .action{
color: #DF1233;

}